import i18n from 'i18next'
import { Convert } from "../Utils/LangConverter";
import constants from '../constants/constants';

const fetchWithCustomHeader = async (url: string, options: any = {}) => {

    let siteCode = sessionStorage.getItem('siteCode') ?? "notfound";
    const lang = Convert(i18n.language);

    const headers = {
      ...options.headers,
      "Content-Type": "application/json",
      "X-Site-Code": siteCode,
      "Accept-Language": lang,
      "X-SPA-Version": `${constants.Version.version}:${constants.Version.build}`,
    };
  
    const response = await fetch(url, {
      ...options,
      headers,
    });
  
    return response;
  };
  
  export default fetchWithCustomHeader;