import Footer2 from "../components/Footer";
import Navbar from "../components/Navbar";
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useEffect, useState } from "react";
import Skeleton from "react-loading-skeleton";
import Overview from "../components/Booking/BookingFindOverview";
import { getMyBooking } from "../services/Booking/api";
import { BookingApi } from "../services/Booking/type";
import { useTranslation } from "react-i18next";
import { useAppSelector } from "../app/hooks";
import { selectBooking } from "../components/Booking/bookingSlice";

function BookingReference() {

  const navigate = useNavigate();

  const bookingState = useAppSelector(selectBooking);
  let [searchParams] = useSearchParams();

  const [segments, setSegments] = useState<BookingApi.Segment[]>([]);
  const { t, i18n } = useTranslation();

  const fetchMyBooking = async () => {
    const data = await getMyBooking(searchParams.get('id') ?? "", searchParams.get('email') ?? "");
    await new Promise((resolve) => setTimeout(resolve, 500));

    if (typeof data === "number") {
      navigate(`/${bookingState.siteCode}?id=${searchParams.get('id')}&email=${searchParams.get('email')}&code=${data}`);
      return;
    }

    setSegments(data);
  };

  const back = () => {
    navigate('/' + bookingState.siteCode);
  };

  useEffect(() => {
    if (!(searchParams.has('email') && searchParams.has('id'))) {
      navigate('/' + bookingState.siteCode + '?' + searchParams.toString());
      return;
    }

    fetchMyBooking();

  }, [searchParams]);

  return (
    <div className="min-h-screen flex flex-col">

      <Navbar />

      <div className="flex-1 bg-white">

        <div className="relative w-full py-20 h-auto bg-white">
          <div className="max-w-[1280px] mx-auto px-[8.66%]">

            <div className="flex justify-between items-end">
              <a onClick={back} className="flex items-center justify-center px-4 py-2 mx-1 text-gray-700 transition-colors duration-300 transform bg-white rounded-md hover:bg-gray-300 hover:text-gray-100">
                <svg xmlns="http://www.w3.org/2000/svg" className="w-5 h-5" viewBox="0 0 20 20" fill="currentColor">
                  <path fillRule="evenodd" d="M12.707 5.293a1 1 0 010 1.414L9.414 10l3.293 3.293a1 1 0 01-1.414 1.414l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 0z" clipRule="evenodd" />
                </svg>

                <span className="hidden md:block">{t('back', { ns: "common" })}</span>
              </a>

              <h1 className="text-2xl font-semibold text-center text-gray-800 capitalize lg:text-3xl">{t('mybooking')}</h1>

              <div className="w-10"></div>

            </div>

            <div className="my-6 xl:mt-12">

              {!segments.some((s) => true) ? (
                <div>
                  <>
                    <Skeleton className="mb-8" height={22} width={125} />
                    {[...Array(4)].map((_, i) => (
                      <div key={i}>
                        <div className="flex">
                          <Skeleton height={22} width={25} />
                          <Skeleton className="mx-4" height={22} width={300} />
                        </div>
                        {i !== 3 && <hr className="my-8 border-gray-200" />}
                      </div>
                    ))}
                  </>
                </div>
              ) : (
                <Overview bookingSegments={segments} />
              )}

            </div>
            <div className="font-medium italic mt-15">
              * {t("cancelpassenger_refoundinfo")}
            </div>

          </div>
        </div>

      </div>

      <Footer2 />

    </div>
  );
}

export default BookingReference;
