import Faq from "../components/Faq/Faq";
import Map from "../components/Map/Map";
import i18n from 'i18next'
import Footer from "../components/Footer";
import LanguagePicker from '../components/LanguagePicker';
import StartBooking from "../components/Booking/BookingStart";
import Carousel from "../components/Carousel";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { useAppSelector } from "../app/hooks";
import { selectBooking } from "../components/Booking/bookingSlice";
import { BookingApi } from "../services/Booking/type";
import { getCarouselData, getTransferLogo } from "../services/Booking/api";

export default function Index() {
  const bookingState = useAppSelector(selectBooking);
  const [searchParams, setSearchParams] = useSearchParams();
  const navigate = useNavigate();
  const [carouselData, setCarouselData] = useState<BookingApi.CarouselImage[]>([]);

  const [logoUrl, setLogoUrl] = useState<string>("");

  useEffect(() => {
    if (searchParams.has('a')) {
      console.log(`Navigate to ${searchParams.get("a")}`);
      navigate("/" + bookingState.siteCode + "/booking");
    }

    const fetchCarouselData = async () => {
      try {
        const data = await getCarouselData();
        setCarouselData(data);
      } catch (error) {
        console.error("Error fetching carousel data:", error);
      }
    };

    fetchCarouselData();

    const getLogo = async () => {
      try {
        const data = await getTransferLogo();
        setLogoUrl(`url('${data?.url != null ? data.url : '/flygtaxi.png'}')`)
      } catch (error) {
        console.error("Error fetching logo url:", error);
      }
    };

    getLogo();

  }, [])
  return (
    <>
      <nav className="absolute w-full max-w-[2560px] p-5 z-20 flex bg-white/30 border-b-gray-300/30 border">
        <div className="bg-center bg-no-repeat bg-contain w-48 hover:cursor-pointer" style={{ backgroundImage: logoUrl }} onClick={ () => navigate("/") } />
        <div className="flex-1 flex items-center justify-end"><LanguagePicker /></div>
      </nav>

      <div id="container" className="mx-auto grid lg:grid-cols-[600px_1fr]">

        <div className="order-2 row-span-2 box-border z-10 bg-white lg:mt-0 lg:max-h-[calc(100vh)] lg:sticky lg:top-0 lg:order-1">
          <StartBooking />
        </div>

        <div className="order-1 lg:order-2 relative z-0 pb-[30vh] lg:pb-[50vh] xl:pb-[70vh]" >
          {carouselData.length > 0 && (
            <Carousel images={carouselData} />
          )}
        </div>

        <div className="order-3 relative z-1 pb-[50vh] lg:pb-[50vh] xl:pb-[70vh]">
          <Map />
        </div>

      </div>

      <Faq filter={[]} />

      <Footer />
    </>
  );
}
