"use client";

import './App.css'
import { Route, Routes } from 'react-router-dom'
import Index from './pages/Index'

import 'react-loading-skeleton/dist/skeleton.css'
import MyBooking from './pages/MyBooking'
import { ToastContainer } from 'react-toastify'
import BookingReference from './pages/BookingReference'
import Messages from './components/Messages'
import ErrorBoundary from './components/ErrorBoundary'
import { useErrorBoundary } from "react-error-boundary";
import SiteRouter from './components/SiteRouter';
import RedirectToDefault from './components/Redirect';
import { useAppSelector } from './app/hooks';
import { selectBooking } from './components/Booking/bookingSlice';
import Transfers from './pages/Transfers';
import constants from './constants/constants';

const BuggyComponent = () => {

  const { showBoundary } = useErrorBoundary();

  const DoShit = () => {
    try {
      throw Error('KAPAOW');
    } catch (error) {
      showBoundary(error);
    }
  };

  return (<button onClick={DoShit} className='bg-red-500 text-red-50 rounded p-4 m-6 mx-auto'>Try me!</button>)
}


function App() {

  const bookingState = useAppSelector(selectBooking);


  return (
    <div className='bg-slate-50'>
      <div className="max-w-[2560px] mx-auto">
        <ErrorBoundary>
          <>          
            <Messages siteCode={bookingState.siteCode} />

            <Routes>
              <Route path="/" element={<Transfers />} />
              <Route path="/:sitecode" element={<SiteRouter />}>
                <Route index path='/:sitecode' element={<Index />} />
                <Route path="/:sitecode/booking" element={<MyBooking />} />
                <Route path="/:sitecode/reference" element={<BookingReference />} />
              </Route>
              <Route path="/v" element={<>{`v${constants.Version.version}:${constants.Version.build}`}</>} />
            </Routes>
          </>
        </ErrorBoundary>

      </div>

      <ToastContainer />

    </div>
  )
}
export default App
