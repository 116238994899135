const getOffset = (timeZone = "UTC", date = new Date()) => {
  const utcDate = new Date(date.toLocaleString("en-US", { timeZone: "UTC" }));
  const tzDate = new Date(date.toLocaleString("en-US", { timeZone }));
  const minutes = (tzDate.getTime() - utcDate.getTime()) / 6e4;
  return (
    (minutes < 0 ? "-" : "+") +
    Math.floor(minutes / 60)
      .toString()
      .padStart(2, "0") +
    ":00"
  );
};

export function swedishTimeToUTC(swedishDateTimeString: string) {
  const offset = getOffset("Europe/Stockholm", new Date(swedishDateTimeString));
  const dateTimeNoOffset = swedishDateTimeString.substring(0, 19);
  const swedishDate = new Date(dateTimeNoOffset + offset);
  const dts = swedishDate.toLocaleString("en-US", { timeZone: "UTC" });
  const date = new Date(dts);
  return `${date.getFullYear()}-${("0" + (date.getMonth() + 1)).slice(-2)}-${(
    "0" + date.getDate()
  ).slice(-2)}T${("0" + date.getHours()).slice(-2)}:${(
    "0" + date.getMinutes()
  ).slice(-2)}:${("0" + date.getSeconds()).slice(-2)}Z`;
}

export function utcTimeToSwedish(utcDateTimeString: string|undefined) {
  if(!utcDateTimeString) return undefined;

  const offset = getOffset("UTC",new Date(utcDateTimeString));
  const dateTimeNoOffset = utcDateTimeString.substring(0, 19);
  const utcDate = new Date(dateTimeNoOffset + offset);
  const dts = utcDate.toLocaleString("en-US", { timeZone: "Europe/Stockholm" });
  const date = new Date(dts);
 const result =  `${date.getFullYear()}-${("0" + (date.getMonth() + 1)).slice(-2)}-${(
    "0" + date.getDate()
  ).slice(-2)}T${("0" + date.getHours()).slice(-2)}:${(
    "0" + date.getMinutes()
  ).slice(-2)}:${("0" + date.getSeconds()).slice(-2)}${getOffset(
    "Europe/Stockholm",new Date(utcDateTimeString)
  )}`;
  return result;
}
