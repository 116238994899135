import { useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import {
  reset,
  BOOKING_STATUS,
  selectBooking,
} from "./bookingSlice";
import { useTranslation } from 'react-i18next';

export default function BookingPending() {
  const dispatch = useAppDispatch();
  const bookingState = useAppSelector(selectBooking);
  const [id, setId] = useState<string>(bookingState.correlationId);
  const { t, i18n } = useTranslation();



  sessionStorage.clear();
  return (
    <>
      <div className="relative md:max-w-[90%] xl:max-w-[1190px] mx-auto my-20 p-6 h-auto bg-white md:rounded-md shadow-md">
        <div className="flex items-center align-middle justify-center flex-col">
          <h1 className="text-xl mt-12 mb-6 font-semibold text-gray-800">{t("bookingPendingTitle", { ns: "translation" })}</h1>
          <p className="font-normal text-gray-700">
            {t("bookingPendingBody", { ns: "translation" })}
          </p>
          <h3 className="text-lg mt-12 mb-6 text-gray-600"><span className="font-bold">Reference number:</span> {id}</h3>

          <div className='w-full flex justify-center'>
            <button onClick={() => { dispatch(reset(bookingState.bookingType)); }} className="w-[50%] items-center align-middle justify-center p-2 mt-16 mb-5 text-white font-bold  bg-[#1d4e9e] rounded-lg border-2 uppercase">
              {t('close', { ns: 'common' })}
            </button>
          </div>

        </div>
      </div>


    </>
  );
}
