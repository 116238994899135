export interface Message {
    id: string;
    message: string;
    type: MessageType;
    showClose: boolean;
    showReload: boolean;
    block: boolean;
}

export enum MessageType {
    Info = 0,
    Error = 1,
}