import { useState, useEffect } from "react";
import Datepicker from "react-tailwindcss-datepicker";
import { DateValueType } from "react-tailwindcss-datepicker/dist/types";
import { useTranslation } from "react-i18next";
import { ArrowsRightLeftIcon } from "@heroicons/react/24/outline";
import { useAppSelector, useAppDispatch } from "../../app/hooks";
import { useNavigate } from "react-router-dom";
import {
  setOutboundConnection,
  setInboundConnection,
  setInboundNode,
  setOutboundNode,
  setTicketType,
  selectBooking,
  setPrice,
  BOOKING_TICKET_TYPE,
  setInAvaTime,
  setOutAvaTime,
  setStatus,
  BOOKING_STATUS,
  swapDirection,
  BOOKING_DIRECTION,
  reset,
  setBookingType,
  TripConnection,
  changeConnection,
} from "./bookingSlice";
import {
  selectLocations,
  setConnectionLocations,
  setLocations,
  swapLocations,
} from "../../app/locationSlice";
import { toast } from "react-toastify";
import {
  getTransportation,
  getLocations,
  getTransferWindow,
  getAvailability,
  getStartLocations,
} from "../../services/Booking/api";
import { BookingApi } from "../../services/Booking/type";
import FlightPicker from "./FlightPicker";
import { BOOKING_TYPE, PickerDetails } from "../../types/booking";
import TrainPicker from "./TrainPicker";
import { useSelector } from "react-redux";
import { useErrorBoundary } from "react-error-boundary";
import { swedishTimeToUTC, utcTimeToSwedish } from "../../Utils/DateUtil";
import { Convert } from "../../Utils/LangConverter";

interface BookingFlightProperties {
  type: BOOKING_TYPE;
}

export default function BookingFlight({ type: type }: BookingFlightProperties) {
  const { t, i18n } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [loadLocations, setLoadLocations] = useState<number>(0);

  const [firstConnections, setFirstConnections] = useState<
    BookingApi.Transportation[]
  >([]);
  const [secondConnections, setSecondConnections] = useState<
    BookingApi.Transportation[]
  >([]);

  const [transferWindow, setTransferWindow] =
    useState<BookingApi.TransferWindow>();

  const bookingState = useAppSelector(selectBooking);
  const locationsState = useAppSelector(selectLocations);

  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const [firstConnectionDate, setFirstConnectionDate] = useState<DateValueType>(
    {
      startDate: bookingState.outboundTrip.connection.arrivalDate,
      endDate: bookingState.outboundTrip.connection.arrivalDate,
    }
  );

  const [secondConnectionDate, setSecondConnectionDate] =
    useState<DateValueType>({
      startDate: bookingState.inboundTrip.connection.departureDate,
      endDate: bookingState.inboundTrip.connection.departureDate,
    });

  const [departPickerKey, setDepartPickerKey] = useState(9999);
  const [arrivePickerKey, setArrivePickerKey] = useState(999999);
  const [collapsedView, setCollapsedView] = useState<boolean>(true);

  const { showBoundary } = useErrorBoundary();


useEffect(()=> {
  if(bookingState.bookingType !== type){
    dispatch(reset(type));
    return;
  }

  if (bookingState.outboundTrip.startNode.code !== "") {
    setCollapsedView(false);
  }

}, []);

useEffect(() => {

  const fetchTransferWindow = async () => {
    let transferWindow = await getTransferWindow(showBoundary);
    const date = new Date();
    if (new Date(transferWindow?.from ?? "0000-00-00") < date) {
      transferWindow.from = date.toISOString().slice(0, 10);
    }

    setTransferWindow(transferWindow);
    setLoadLocations(1);

    if (bookingState.outboundTrip.connection.number !== "") {
      const cc =
        bookingState.direction === BOOKING_DIRECTION.FROM
          ? bookingState.outboundTrip.startNode.code
          : bookingState.outboundTrip.endNode.code;

      setFirstConnections(
        await getTransports(
          bookingState.outboundTrip.connection.arrivalDate
            ?.toString()
            .slice(0, 10),
          cc,
          bookingState.direction === BOOKING_DIRECTION.FROM ? "A" : "D"
        )
      );
      setSecondConnections(
        await getTransports(
          bookingState.outboundTrip.connection.departureDate
            ?.toString()
            .slice(0, 10),
          cc,
          bookingState.direction === BOOKING_DIRECTION.FROM ? "D" : "A"
        )
      );
      setArrivePickerKey(arrivePickerKey + 1);
      setDepartPickerKey(departPickerKey + 1);

      return;
    }
  };
  fetchTransferWindow();
  setCollapsedView(true)
}, []);


  useEffect(() => {
    if(bookingState.bookingType !== type){
      return;
    }
    if (collapsedView) {

      if (
        bookingState.direction === BOOKING_DIRECTION.FROM &&
        bookingState.outboundTrip.endNode.code !== ""
      ) {
        setCollapsedView((c) => false);
      }

      if (
        bookingState.direction === BOOKING_DIRECTION.TO &&
        bookingState.outboundTrip.startNode.code !== ""
      ) {
        setCollapsedView((c) => false);
      }
    }
  }, [bookingState.outboundTrip.endNode.code]);

  useEffect(() => {
    const fetchData = async () => {
      const now = new Date();
      let locationDate = transferWindow?.from ?? now.toISOString().slice(0, 10);

      const start = await getStartLocations(type);

      const connections = start.map((d, i) => ({
        name: d.name,
        code: d.locationCode,
        distance: d.distance,
        duration: d.duration,
        latitude: d.latitude,
        locationCode: d.locationCode,
        longitude: d.longitude,
        productCode: d.productCode,
        productName: d.productName,
        serviceName: d.serviceName,
        connection: true,
        externalLocationCode: d.externalLocationCode,
      }));

      let sc = "";
      let direction = "A";

      switch (bookingState.direction) {
        case BOOKING_DIRECTION.FROM:
          direction = "A";
          sc = bookingState.outboundTrip.startNode.code === '' ? connections[0].locationCode : bookingState.outboundTrip.startNode.code;
          if (bookingState.outboundTrip.connection.arrivalDate) {
            locationDate = bookingState.outboundTrip.connection.arrivalDate
              .toString()
              .slice(0, 10);
          }
          break;
        case BOOKING_DIRECTION.TO:
          direction = "D";
          sc = bookingState.outboundTrip.endNode.code === '' ? connections[0].locationCode : bookingState.outboundTrip.endNode.code;
          if (bookingState.outboundTrip.connection.departureDate) {
            locationDate = bookingState.outboundTrip.connection.departureDate
              .toString()
              .slice(0, 10);
          }
          break;
      }


      const destinations = await getLocations(locationDate, sc, direction);

      const locations = destinations.map((d, i) => ({
        name: d.name,
        code: d.locationCode,
        distance: d.distance,
        duration: d.duration,
        latitude: d.latitude,
        locationCode: d.locationCode,
        longitude: d.longitude,
        productCode: d.productCode,
        productName: d.productName,
        serviceName: d.serviceName,
        connection: false,
        externalLocationCode: undefined,
      }));

      if (bookingState.direction === BOOKING_DIRECTION.FROM) {
        dispatch(
          setOutboundNode({
            start: sc,
            end: bookingState.outboundTrip.endNode.code,
          })
        );
        dispatch(
          setInboundNode({
            start: bookingState.outboundTrip.endNode.code,
            end: sc,
          })
        );

        dispatch(setConnectionLocations(connections));
        dispatch(setLocations(locations));
      } else {
        dispatch(
          setOutboundNode({
            start: bookingState.outboundTrip.startNode.code,
            end: sc,
          })
        );
        dispatch(
          setInboundNode({
            start: sc,
            end: bookingState.outboundTrip.endNode.code,
          })
        );

        dispatch(setLocations(connections));
        dispatch(setConnectionLocations(locations));
      }
    };
    if (loadLocations > 0) {
      fetchData();
    }
  }, [loadLocations]);



  const handleFirstConnectionDateChange = async (newValue: DateValueType) => {
    if (newValue === null) return;

    const cc =
      bookingState.direction === BOOKING_DIRECTION.FROM
        ? bookingState.outboundTrip.startNode.code
        : bookingState.outboundTrip.endNode.code;

    setFirstConnectionDate(newValue);
    if (bookingState.bookingType === BOOKING_TYPE.TRAIN) {
      return;
    }
    const transportations = await getTransports(
      newValue.startDate?.toString().slice(0, 10) ?? "",
      cc,
      bookingState.direction === BOOKING_DIRECTION.FROM ? "A" : "D"
    );

    setFirstConnections(transportations);
    setArrivePickerKey(arrivePickerKey + 1);

    dispatch(
      setOutboundConnection({
        type: "",
        headSign: undefined,
        number: "",
        departureDate: null,
        departureCode: "",
        departureName: "",
        arrivalDate: null,
        arrivalCode: "",
        arrivalName: "",
        international: false,
        carrierName: "",
      } as TripConnection)
    );
  };

  const handleSecondConnectionDateChange = async (newValue: DateValueType) => {
    if (newValue === null) return;

    const cc =
      bookingState.direction === BOOKING_DIRECTION.FROM
        ? bookingState.outboundTrip.startNode.code
        : bookingState.outboundTrip.endNode.code;

    const transportations = await getTransports(
      newValue.startDate?.toString().slice(0, 10) ?? "",
      cc,
      bookingState.direction === BOOKING_DIRECTION.FROM ? "D" : "A"
    );

    setSecondConnectionDate(newValue);
    setSecondConnections(transportations);
    setDepartPickerKey(departPickerKey + 1);

    dispatch(
      setInboundConnection({
        type: "",
        headSign: undefined,
        number: "",
        departureDate: null,
        departureCode: "",
        departureName: "",
        arrivalDate: null,
        arrivalCode: "",
        arrivalName: "",
        international: false,
        carrierName: "",
      } as TripConnection)
    );
  };

  const getTransports = async (
    date: string | undefined,
    connectionCode: string,
    direction: string
  ): Promise<BookingApi.Transportation[]> => {
    if (date === undefined) return [];

    var t = await getTransportation(
      date,
      direction,
      type == BOOKING_TYPE.FLIGHT ? "FLIGHT" : "TRAIN",
      connectionCode
    );

    return t;
  };

  const outboundConnectionUpdated = (details: PickerDetails) => {


    if (details.number === "" && details.time === "") return;

    let f: BookingApi.Transportation | undefined;
    if (type === BOOKING_TYPE.FLIGHT)
      f = firstConnections.find((f) => f.number == details.number);

    if (!f) {
      f = manualSetSelected(details, bookingState.direction);
    }

    dispatch(setOutboundConnection(f));
  };

  const inboundConnectionUpdated = (details: PickerDetails) => {
    if (details.number === "" && details.time === "") return;

    let f: BookingApi.Transportation | undefined;
    if (type === BOOKING_TYPE.FLIGHT)
      f = secondConnections.find((f) => f.number === details.number);

    if (!f) {
      f = manualSetSelected(details, bookingState.direction);
    }

    dispatch(setInboundConnection(f));
  };

  const manualSetSelected = (
    details: PickerDetails,
    direction: BOOKING_DIRECTION
  ): BookingApi.Transportation => {
    if (details.direction.toLowerCase() === "a") {
      const date = swedishTimeToUTC(details.date + "T" + (details.time ?? "00:00"))
      const dep = new Date(date);
      dep.setHours(dep.getHours() - 1);

      return {
        type: details.connectionType,
        headSign: undefined,
        number: details.number,
        departureDate: dep?.toISOString() ?? "",
        departureCode: "INR",
        departureName: "",
        arrivalDate: date,
        arrivalCode:
          direction === BOOKING_DIRECTION.FROM
            ? bookingState.outboundTrip.startNode.code
            : bookingState.outboundTrip.endNode.code,
        arrivalName: "",
        international: false,
        carrierName: "",
      } as BookingApi.Transportation;
    } else if (details.direction.toLowerCase() === "d") {
      const date = swedishTimeToUTC(details.date + "T" + (details.time ?? "00:00"))

      const ari = new Date(date);
      ari.setHours(ari.getHours() + 1);

      return {
        type: details.connectionType,
        headSign: undefined,
        number: details.number,
        departureDate: date,
        departureCode:
          direction === BOOKING_DIRECTION.FROM
            ? bookingState.outboundTrip.startNode.code
            : bookingState.outboundTrip.endNode.code,
        departureName: "",
        arrivalDate: ari?.toISOString() ?? "",
        arrivalCode: "INR",
        arrivalName: "",
        international: false,
        carrierName: "",
      } as BookingApi.Transportation;
    }

    return {
      type: "",
      headSign: undefined,
      number: "",
      departureDate: "",
      departureCode: "",
      departureName: "",
      arrivalDate: "",
      arrivalCode: "",
      arrivalName: "",
      international: false,
      carrierName: "",
    } as BookingApi.Transportation;
  };

  function handleSwapLocationChange() {
    dispatch(swapLocations());
    dispatch(swapDirection());

    setFirstConnectionDate({ startDate: null, endDate: null });
    setSecondConnectionDate({ startDate: null, endDate: null });
    setFirstConnections([]);
    setSecondConnections([]);
    setDepartPickerKey(departPickerKey + 1);
    setArrivePickerKey(arrivePickerKey + 1);
  }

  function handleFromLocationChange(selectedValue: string) {
    if (
      bookingState.direction === BOOKING_DIRECTION.FROM &&
      selectedValue !== bookingState.outboundTrip.startNode.code
    ) {
      dispatch(changeConnection());
      setFirstConnectionDate({ startDate: null, endDate: null });
      setSecondConnectionDate({ startDate: null, endDate: null });
      setFirstConnections([]);
      setSecondConnections([]);
      setDepartPickerKey(departPickerKey + 1);
      setArrivePickerKey(arrivePickerKey + 1);

      setLoadLocations((current) => {
        return current + 1;
      });
    }

    if (bookingState.ticketType === BOOKING_TICKET_TYPE.ONEWAY) {
      dispatch(setInboundNode({ start: "", end: "" }));
      dispatch(
        setOutboundNode({
          start: selectedValue,
          end: bookingState.outboundTrip.endNode.code,
        })
      );
    } else if (bookingState.ticketType === BOOKING_TICKET_TYPE.RETURN) {
      dispatch(
        setInboundNode({
          start: bookingState.outboundTrip.endNode.code,
          end: selectedValue,
        })
      );
      dispatch(
        setOutboundNode({
          start: selectedValue,
          end: bookingState.outboundTrip.endNode.code,
        })
      );
    }

    validateDestinationIsSet(
      selectedValue,
      bookingState.outboundTrip.endNode.code
    );
  }

  function handleToLocationChange(selectedValue: string) {
    if (
      bookingState.direction === BOOKING_DIRECTION.TO &&
      selectedValue !== bookingState.inboundTrip.endNode.code
    ) {
      dispatch(changeConnection());
      setFirstConnectionDate({ startDate: null, endDate: null });
      setSecondConnectionDate({ startDate: null, endDate: null });
      setFirstConnections([]);
      setSecondConnections([]);
      setDepartPickerKey(departPickerKey + 1);
      setArrivePickerKey(arrivePickerKey + 1);

      setLoadLocations((current) => {
        return current + 1;
      });
    }

    if (bookingState.ticketType === BOOKING_TICKET_TYPE.ONEWAY) {
      dispatch(setInboundNode({ start: "", end: "" }));
      dispatch(
        setOutboundNode({
          start: bookingState.outboundTrip.startNode.code,
          end: selectedValue,
        })
      );
    } else if (bookingState.ticketType === BOOKING_TICKET_TYPE.RETURN) {
      dispatch(
        setInboundNode({
          start: selectedValue,
          end: bookingState.outboundTrip.startNode.code,
        })
      );
      dispatch(
        setOutboundNode({
          start: bookingState.outboundTrip.startNode.code,
          end: selectedValue,
        })
      );
    }
    firstConnectionDate;
    validateDestinationIsSet(
      bookingState.outboundTrip.startNode.code,
      selectedValue
    );
  }

  function validateDestinationIsSet(from: string, to: string): boolean {
    const allLocations = locationsState.locationsB.concat(
      locationsState.locationsA
    );
    if (
      !allLocations.some((l) => l.locationCode == from) ||
      !allLocations.some((l) => l.locationCode == to)
    ) {

      setCollapsedView(true);
      return false;
    }

    setCollapsedView(false);
    return true;
  }

  function handleSetTicketType(ticketType: BOOKING_TICKET_TYPE) {
    dispatch(setTicketType(ticketType));

    if (ticketType === BOOKING_TICKET_TYPE.RETURN) {
      dispatch(
        setInboundNode({
          start: bookingState.outboundTrip.endNode.code,
          end: bookingState.outboundTrip.startNode.code,
        })
      );
    } else if (ticketType === BOOKING_TICKET_TYPE.ONEWAY) {
      dispatch(setInboundNode({ start: "", end: "" }));
    }
  }

  async function onContinuebook() {
    if (
      bookingState.outboundTrip.connection.number === "" ||
      bookingState.outboundTrip.connection.arrivalDate === "" ||
      (bookingState.ticketType == BOOKING_TICKET_TYPE.RETURN &&
        (bookingState.inboundTrip.connection.number === "" ||
          bookingState.inboundTrip.connection.departureDate === ""))
    )
      return;

    setLoading(true);

    let outboundAva: BookingApi.Availability[] = [];
    let inboundAva: BookingApi.Availability[] = [];
    let outDate: string | undefined;
    let inDate: string | undefined;

    switch (bookingState.ticketType) {
      case BOOKING_TICKET_TYPE.ONEWAY:
        outDate = bookingState.direction == BOOKING_DIRECTION.FROM ? bookingState.outboundTrip.connection.arrivalDate?.toString() : bookingState.outboundTrip.connection.departureDate?.toString();
        if (!outDate) break;
        outboundAva = await getAvailability(
          outDate,
          bookingState.outboundTrip.startNode.code,
          bookingState.outboundTrip.endNode.code
        );

        if (!outboundAva.some((t) => true)) {
          toast.info(t("toast.info_noticketsAva"));
          setTimeout(() => {
            setLoading(false);
          }, 1000);
          return;
        }

        break;
      case BOOKING_TICKET_TYPE.RETURN:
        outDate = bookingState.direction == BOOKING_DIRECTION.FROM ? bookingState.outboundTrip.connection.arrivalDate?.toString() : bookingState.outboundTrip.connection.departureDate?.toString();
        inDate = bookingState.direction == BOOKING_DIRECTION.FROM ? bookingState.inboundTrip.connection.departureDate?.toString() : bookingState.inboundTrip.connection.arrivalDate?.toString();
        if (!outDate || !inDate) break;

        outboundAva = await getAvailability(
          outDate,
          bookingState.outboundTrip.startNode.code,
          bookingState.outboundTrip.endNode.code
        );
        inboundAva = await getAvailability(
          inDate,
          bookingState.inboundTrip.startNode.code,
          bookingState.inboundTrip.endNode.code
        );

        if (!outboundAva.some((t) => true) || !inboundAva.some((t) => true)) {
          toast.info(t("toast.info_noticketsAva"));
          setTimeout(() => {
            setLoading(false);
          }, 1000);
          return;
        }

        break;
    }

    const outava = outboundAva.shift();
    const inava = inboundAva.shift();
    UpdateAva(outava, inava);
    setTimeout(() => {
      setLoading(false);
    }, 1000);
    dispatch(setStatus(BOOKING_STATUS.AVAILABILITY));
    console.log(bookingState.siteCode);
    navigate('/'+bookingState.siteCode+"/booking");
  }

  const UpdateAva = async (
    outAvailability: BookingApi.Availability | undefined,
    inAvailability: BookingApi.Availability | undefined
  ) => {
    const adultPrice = parseInt(
      outAvailability?.price.includingVat ||
      outAvailability?.price.includingVat ||
      ""
    );
    const childPrice =
      adultPrice *
      ((outAvailability?.product.childDiscountPercent ||
        inAvailability?.product.childDiscountPercent ||
        0) /
        100);

    dispatch(setPrice({ adult: adultPrice, child: childPrice }));

    dispatch(
      setOutAvaTime([
        outAvailability?.startNode.time ?? "",
        outAvailability?.endNode.time ?? "",
      ])
    );

    dispatch(
      setInAvaTime([
        inAvailability?.startNode.time ?? "",
        inAvailability?.endNode.time ?? "",
      ])
    );
  };

  function scrollElementToTop(event: React.MouseEvent<HTMLElement>): void {

    const target = event.target as HTMLElement;
    if (
      target.tagName.toLowerCase() !== "input" ||
      (target as HTMLInputElement)?.disabled
    )
      return;

    const isMobile = window.matchMedia("(max-width: 767px)").matches;

    if (isMobile) {
      const rect = target.getBoundingClientRect();
      const scrollTop =
        window.pageYOffset || document.documentElement.scrollTop;
      const top = rect.top + scrollTop;
      const offset = 50 + (document.getElementById("messages")?.clientHeight ?? 0);

      window.scrollTo({ top: top - offset, behavior: "smooth" });
    }
  }

  function focusMap() {
    const element = document.getElementById("map");
    if (!element) return;

    const isMobile = window.matchMedia("(min-width: 767px)").matches;

    if (isMobile) {
      const top =
        window.scrollY +
        element.getBoundingClientRect().top +
        element.getBoundingClientRect().height -
        window.innerHeight;
      window.scrollTo({
        left: 0,
        top: top,
        behavior: "smooth",
      });
    }
  }

  const clear = () => {
    dispatch(reset(type));
    setCollapsedView(true);
    setFirstConnectionDate({ startDate: null, endDate: null });
    setSecondConnectionDate({ startDate: null, endDate: null });
    setFirstConnections([]);
    setSecondConnections([]);
    setDepartPickerKey(departPickerKey + 1);
    setArrivePickerKey(arrivePickerKey + 1);
    setLoadLocations((current) => {
      return current + 1;
    });
  }

  return (
    <>
      <div className="grid grid-cols-1 gap-6 mt-4 md:grid-cols-2">
        {/* Row 2  - TO and FROM */}

        <div className="md:col-span-2 grid gap-x-1 gap-y-1 grid-rows-[auto_1px_auto] md:gap-x-1 md:gap-y-1 md:grid-rows-1 md:grid-cols-[1fr_20px_1fr]">
          {/* FROM COLUMN */}
          <div>
            <label
              className="text-gray-700 hover:cursor-pointer"
              htmlFor="from"
            >
              {t("from", { ns: "common" })}
            </label>
            <div className="relative">
              <select
                id="from"
                className="block w-full px-4 py-2 mt-2 text-gray-700 bg-white border border-gray-200 rounded-md focus:border-blue-400 focus:ring-blue-300 focus:ring-opacity-40 focus:outline-none focus:ring appearance-none hover:cursor-pointer"
                onChange={(event) =>
                  handleFromLocationChange(event.target.value)
                }
                onFocus={focusMap}
                value={bookingState.outboundTrip.startNode.code ?? ""}
              //disabled={bookingState.outboundTrip.startNode.code === startCode}
              >
                {locationsState.locationsA.every((l) => !l.connection) && (
                  <option value="">{t("choosestop", { ns: "common" })}</option>
                )}
                {locationsState.locationsA.map((item) => (
                  <option key={item.locationCode} value={item.locationCode}>
                    {item.name}
                  </option>
                ))}
              </select>
              <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
                <svg
                  className="fill-current h-4 w-4"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 20 20"
                >
                  <path d="M10 12l-5-5 1.41-1.41L10 9.18l3.59-3.59L15 7l-5 5z" />
                </svg>
              </div>
            </div>
          </div>

          {/* FLIP */}
          <div className="flex py-4 transform rotate-90 md:rotate-0 md:py-3 justify-center items-center md:items-end">
            <ArrowsRightLeftIcon
              className="w-6 text-zinc-700 hover:text-zinc-900 hover:cursor-pointer"
              onClick={() => handleSwapLocationChange()}
            />
          </div>

          {/* TO COLUMN */}
          <div>
            <label className="text-gray-700 hover:cursor-pointer" htmlFor="to">
              {t("to", { ns: "common" })}
            </label>
            <div className="relative">
              <select
                id="to"
                className="block w-full px-4 py-2 mt-2 text-gray-700 bg-white border border-gray-200 rounded-md focus:border-blue-400 focus:ring-blue-300 focus:ring-opacity-40 focus:outline-none focus:ring appearance-none hover:cursor-pointer"
                onChange={(event) => handleToLocationChange(event.target.value)}
                onFocus={focusMap}
                value={bookingState.outboundTrip.endNode.code ?? ""}
              //disabled={bookingState.outboundTrip.endNode.code === startCode}
              >
                {locationsState.locationsB.every((l) => !l.connection) && (
                  <option value="">{t("choosestop", { ns: "common" })}</option>
                )}
                {locationsState.locationsB.map((item) => (
                  <option key={item.locationCode} value={item.locationCode}>
                    {item.name}
                  </option>
                ))}
              </select>
              <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
                <svg
                  className="fill-current h-4 w-4"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 20 20"
                >
                  <path d="M10 12l-5-5 1.41-1.41L10 9.18l3.59-3.59L15 7l-5 5z" />
                </svg>
              </div>
            </div>
          </div>
        </div>

        {collapsedView ? (
          // COLLASPSED
          <div className="py-1"></div>
        ) : (
          // NOT COLLASPSED
          <>
            {/* Row 1  - Round Trip one way */}

            <div className="md:col-span-2 flex">
              <div className="flex gap-6 px-4 py-2 mt-2 rounded-md border border-gray-200">
                <div>
                  <input
                    type="radio"
                    id="roundtrip"
                    name="trip"
                    className="mr-2 hover:cursor-pointer"
                    value={BOOKING_TICKET_TYPE.RETURN}
                    checked={
                      bookingState.ticketType === BOOKING_TICKET_TYPE.RETURN
                    }
                    onChange={() =>
                      handleSetTicketType(BOOKING_TICKET_TYPE.RETURN)
                    }
                  />
                  <label htmlFor="roundtrip" className="hover:cursor-pointer">
                    {t("roundtrip", { ns: "common" })}
                  </label>
                </div>
                <div>
                  <input
                    type="radio"
                    id="oneway"
                    name="trip"
                    className="mr-2 hover:cursor-pointer"
                    value={BOOKING_TICKET_TYPE.ONEWAY}
                    checked={
                      bookingState.ticketType === BOOKING_TICKET_TYPE.ONEWAY
                    }
                    onChange={() =>
                      handleSetTicketType(BOOKING_TICKET_TYPE.ONEWAY)
                    }
                  />
                  <label htmlFor="oneway" className="hover:cursor-pointer">
                    {t("oneway", { ns: "common" })}
                  </label>
                </div>
              </div>
              <div className="flex-1" />
            </div>

            {/* Row 3  - Arrival */}

            <>
              {/* Arrival date */}

              <div>
                <label
                  className="text-gray-700 mb-6 hover:cursor-pointer"
                  htmlFor="arrivaldate"
                >
                  {bookingState.direction === BOOKING_DIRECTION.FROM
                    ? t("arrivaldate", { ns: "common" })
                    : t("depaturedate", { ns: "common" })}
                </label>
                <div className="flex flex-col">
                  <div className="w-full h-2" />
                  <div onClick={scrollElementToTop}>
                    <Datepicker
                      inputId="arrivaldate"
                      i18n={Convert(i18n.language).substring(2)}
                      inputClassName="w-full px-4 py-2 text-gray-700 bg-white border border-gray-200 rounded-md focus:border-blue-400 focus:ring-blue-300 focus:ring-opacity-40 focus:outline-none focus:ring appearance-none"
                      readOnly={true}
                      asSingle={true}
                      useRange={false}
                      showShortcuts={false}
                      showFooter={false}
                      primaryColor={"blue"}
                      value={firstConnectionDate}
                      placeholder={
                        bookingState.direction === BOOKING_DIRECTION.FROM
                          ? t("arrivaldate", { ns: "common" }) ?? ""
                          : t("depaturedate", { ns: "common" }) ?? ""
                      }
                      onChange={handleFirstConnectionDateChange}
                      minDate={transferWindow?.from}
                      maxDate={
                        new Date(transferWindow?.to ?? "2320-01-01") <
                          new Date(
                            secondConnectionDate?.startDate?.toString() ??
                            "2320-01-01"
                          )
                          ? transferWindow?.to
                          : secondConnectionDate?.startDate
                      }
                      startFrom={new Date(transferWindow?.from ?? new Date())}
                      startWeekOn="mon" 
                      
                    />
                  </div>
                </div>
              </div>

              {/* Arrival flight */}
              {type == BOOKING_TYPE.FLIGHT ? (
                <div onClick={scrollElementToTop}>
                  <FlightPicker
                    key={arrivePickerKey}
                    flights={firstConnections}
                    value={{
                      connectionType: "FLIGHT",
                      number: bookingState.outboundTrip.connection.number,
                      time: bookingState.outboundTrip.connection.arrivalDate
                        ?.toString()
                        .substring(11, 16),
                      date: firstConnectionDate?.startDate
                        ?.toString()
                        .slice(0, 10),
                      direction:
                        bookingState.direction === BOOKING_DIRECTION.FROM
                          ? "A"
                          : "D",
                      connectionCode:
                        locationsState.locationsA
                          .concat(locationsState.locationsB)
                          .find(
                            (l) =>
                              l.locationCode ==
                              bookingState.outboundTrip.startNode.code
                          )?.locationCode ?? "",

                    }}
                    onChange={outboundConnectionUpdated}
                  />
                </div>
              ) : null}
              {type == BOOKING_TYPE.TRAIN ? (
                <div onClick={scrollElementToTop}>
                  <TrainPicker
                    key={arrivePickerKey}
                    value={{
                      connectionType: "TRAIN",
                      number: bookingState.outboundTrip.connection.number,
                      time:
                        bookingState.outboundTrip.connection.arrivalDate
                          ?.toString()
                          .substring(11, 16) ?? "12:00",
                      date: firstConnectionDate?.startDate
                        ?.toString()
                        .slice(0, 10),
                      direction:
                        bookingState.direction === BOOKING_DIRECTION.FROM
                          ? "A"
                          : "D",
                      connectionCode:
                        locationsState.locationsA
                          .concat(locationsState.locationsB)
                          .find(
                            (l) =>
                              l.locationCode ==
                              bookingState.outboundTrip.startNode.code
                          )?.externalLocationCode ?? "",
                    }}
                    onChange={outboundConnectionUpdated}
                  />
                </div>
              ) : null}
            </>

            {/* Row 4  - Return */}

            {bookingState.ticketType != BOOKING_TICKET_TYPE.ONEWAY && (
              <>
                {/* Depart date */}
                <div onClick={scrollElementToTop}>
                  <label
                    className="text-gray-700 mb-6 hover:cursor-pointer"
                    htmlFor="departdate"
                  >
                    {bookingState.direction === BOOKING_DIRECTION.FROM
                      ? t("depaturedate", { ns: "common" })
                      : t("arrivaldate", { ns: "common" })}
                  </label>
                  <div className="flex flex-col">
                    <div className="w-full h-2" />
                    <Datepicker
                      inputId="departdate"
                      i18n={Convert(i18n.language).substring(2)}
                      inputClassName="w-full px-4 py-2 text-gray-700 bg-white border border-gray-200 rounded-md focus:border-blue-400 focus:ring-blue-300 focus:ring-opacity-40 focus:outline-none focus:ring appearance-none"
                      readOnly={true}
                      asSingle={true}
                      useRange={false}
                      showShortcuts={false}
                      showFooter={false}
                      primaryColor={"blue"}
                      value={secondConnectionDate}
                      placeholder={
                        bookingState.direction === BOOKING_DIRECTION.FROM
                          ? t("depaturedate", { ns: "common" }) ?? ""
                          : t("arrivaldate", { ns: "common" }) ?? ""
                      }
                      onChange={handleSecondConnectionDateChange}
                      minDate={
                        new Date(transferWindow?.from ?? "2020-01-01") >
                          new Date(
                            firstConnectionDate?.startDate?.toString() ??
                            "2020-01-01"
                          )
                          ? transferWindow?.from
                          : firstConnectionDate?.startDate
                      }
                      maxDate={transferWindow?.to}
                      startFrom={ firstConnectionDate?.startDate ?  new Date(firstConnectionDate?.startDate?.toString() ?? new Date()) : new Date()}
                      startWeekOn="mon" 
                    />
                  </div>
                </div>

                {/* Depart flight */}

                {type == BOOKING_TYPE.FLIGHT ? (
                  <div onClick={scrollElementToTop}>
                    <FlightPicker
                      key={departPickerKey}
                      flights={secondConnections}
                      value={{
                        connectionType: "FLIGHT",
                        number: bookingState.inboundTrip.connection.number,
                        time: bookingState.inboundTrip.connection.departureDate
                          ?.toString()
                          .substring(11, 16),
                        date: secondConnectionDate?.startDate
                          ?.toString()
                          .slice(0, 10),
                        direction:
                          bookingState.direction === BOOKING_DIRECTION.FROM
                            ? "D"
                            : "A",
                        connectionCode:
                          locationsState.locationsA
                            .concat(locationsState.locationsB)
                            .find(
                              (l) =>
                                l.locationCode ==
                                bookingState.inboundTrip.endNode.code
                            )?.locationCode ?? "",
                      }}
                      onChange={inboundConnectionUpdated}
                    />
                  </div>
                ) : null}
                {type == BOOKING_TYPE.TRAIN ? (
                  <div onClick={scrollElementToTop}>
                    <TrainPicker
                      key={departPickerKey}
                      value={{
                        connectionType: "TRAIN",
                        number: bookingState.inboundTrip.connection.number,
                        time:
                          bookingState.inboundTrip.connection.departureDate
                            ?.toString()
                            .substring(11, 16) ?? "12:00",
                        date: secondConnectionDate?.startDate
                          ?.toString()
                          .slice(0, 10),
                        direction:
                          bookingState.direction === BOOKING_DIRECTION.FROM
                            ? "D"
                            : "A",
                        connectionCode:
                          locationsState.locationsA
                            .concat(locationsState.locationsB)
                            .find(
                              (l) =>
                                l.locationCode ==
                                bookingState.inboundTrip.endNode.code
                            )?.externalLocationCode ?? "",
                      }}
                      onChange={inboundConnectionUpdated}
                    />
                  </div>
                ) : null}
              </>
            )}

            {/* Row 5 - Continue */}

            <div className="w-full flex flex-col-reverse items-center gap-y-6 my-6 md:col-span-2 lg:flex-row lg:justify-between">

              <button onClick={clear} className={`w-full py-4 px-12 text-white font-bold bg-gray-500 border-1 uppercase lg:w-auto`} >
                {t("clear", { ns: "common" })}
              </button>

              <button
                onClick={() => {
                  onContinuebook();
                }}
                className={`w-full py-4 px-12 text-white font-bold bg-ft-blue-dark border-1 uppercase lg:w-auto
                    ${bookingState.outboundTrip.connection.number === "" ||
                    bookingState.outboundTrip.connection.arrivalDate === "" ||
                    (bookingState.ticketType == BOOKING_TICKET_TYPE.RETURN &&
                      (bookingState.inboundTrip.connection.number === "" ||
                        bookingState.inboundTrip.connection.departureDate ===
                        ""))
                    ? "opacity-50 cursor-not-allowed"
                    : ""
                  }`}
              >
                {t("continue", { ns: "common" })}
              </button>
            </div>
          </>
        )}

        {/* {loading ? (

          [...Array(4)].map((_, i) => (
            <div key={i}>

              <div className='flex p-5'>
                <Skeleton height={22} width={25} />
                <Skeleton className='mx-4' height={22} width={400} />
              </div>
              {
                i !== 3 &&
                <hr className="my-8 border-gray-200" />
              }
            </div>
          ))

        ) */}
      </div>
    </>
  );
}
