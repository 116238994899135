import { useEffect, useState } from "react";
import { ErrorBoundary as ReactErrorBoundary, FallbackProps } from "react-error-boundary";
import constants from "../constants/constants";
import { toast } from "react-toastify";

interface ReactErrorBoundaryProps {
    children: React.ReactNode;
}

interface LogRecord {
    error: Error;
    errorInfo: React.ErrorInfo;
}

const logs: LogRecord[] = [];

function fallbackRender({ error, resetErrorBoundary }: FallbackProps) {

    const [showContent, setShowContent] = useState(false);

    useEffect(() => {
        const timer = setTimeout(() => {
            setShowContent(true);
        }, 200);

        return () => clearTimeout(timer);
    }, []);      

        const handleCopyToClipboard = () => {
          const data = GetTroubleShootingData();
      
          if (data) {
            navigator.clipboard.writeText(JSON.stringify(data, null, 2))
              .then(() => {
                toast.info("Copied error log");
              })
              .catch((error) => {
                console.error('Unable to copy to clipboard:', error);
              });
          }
        };

    return (

        <div className={`absolute inset-0 w-full text-white bg-red-900`}>
            {
                showContent
                    ?
                    (<>
                        <h1 className="relative text-3xl text-center p-6 mt-[25%]">Something went wrong :(</h1>

                        <button onClick={resetErrorBoundary} className="relative mt-6 p-3 mx-auto flex border border-white transition-colors duration-300 transform rounded-md hover:bg-opacity-25 hover:bg-gray-600 focus:outline-none">

                            <p className="mx-3">Restart</p>

                            <svg className="w-6 h-6" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
                                <path fillRule="evenodd" d="M4 2a1 1 0 011 1v2.101a7.002 7.002 0 0111.601 2.566 1 1 0 11-1.885.666A5.002 5.002 0 005.999 7H9a1 1 0 010 2H4a1 1 0 01-1-1V3a1 1 0 011-1zm.008 9.057a1 1 0 011.276.61A5.002 5.002 0 0014.001 13H11a1 1 0 110-2h5a1 1 0 011 1v5a1 1 0 11-2 0v-2.101a7.002 7.002 0 01-11.601-2.566 1 1 0 01.61-1.276z" clipRule="evenodd" />
                            </svg>

                        </button>

                        <div className="absolute bottom-20 w-96 left-1/2 transform -translate-x-1/2">
                            <h3 className="uppercase font-semibold text-center">Contact us</h3>

                            <div className="flex items-center justify-between">
                                <span className="block mt-2 text-sm">+46 8120 920 00</span>
                                <a href={`mailto:support@flygtaxi.se?subject=${"Transfer error"}&body=${'%0D%0A%0D%0A%0D%0A-------------------------------%0D%0ATroubleshooting log:%0D%0A%0D%0A'+JSON.stringify(GetTroubleShootingData(), null, 2)}`} className="block mt-2 text-sm hover:underline">support@flygtaxi.se</a>
                                <a onClick={handleCopyToClipboard} className="block mt-2 mr-12 text-sm hover:underline cursor-pointer">Copy log</a>
                            </div>

                            <h5 className="text-center text-xs mt-5">{`v${constants.Version.version}:${constants.Version.build}`}</h5>
                        </div>
                    </>)
                    :
                    (<><h1 className="relative text-3xl text-center p-6 mt-[25%]">Loading..</h1></>)
            }
        </div>
    );
}

function handleError(error: Error, errorInfo: React.ErrorInfo) {
    logs.push({ error, errorInfo });
}

function generateGuid() {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
      const r = (Math.random() * 16) | 0;
      const v = c === 'x' ? r : (r & 0x3) | 0x8;
      return v.toString(16);
    });
  }

function handleReset() {
    window.sessionStorage.clear();
    window.location.href = `/?cacheBuster=${generateGuid()}`;
}

function GetTroubleShootingData() {        
    const bookingState = sessionStorage.getItem('bookingState');
    const siteCode = sessionStorage.getItem('siteCode');
    const i18nextLng = localStorage.getItem('i18nextLng');
    const browser = navigator.userAgent;
    const scriptTag = document.querySelector('script[type="module"]');
    const srcAttribute = scriptTag?.getAttribute('src');
  
    return {
      version: constants.Version,
      logs: logs,
      bookingState: bookingState,
      i18nextLng: i18nextLng,
      siteCode: siteCode,
      browser: browser,
      indexFileHash: srcAttribute,
      timestamp: new Date().toISOString(),            
    };
  }

async function sendMetricsToAPI() {
    if (logs.length < 1)
        return;

    const response = await fetch(
        `${constants.Api.API_URL}/clientlogs`,
        {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify(GetTroubleShootingData()),
        }
    );

    if (response.ok) {
        logs.length = 0;
    }
}


const ErrorBoundary = (props: ReactErrorBoundaryProps) => {
    window.addEventListener('beforeunload', sendMetricsToAPI);

    return (
        <ReactErrorBoundary
            FallbackComponent={fallbackRender}
            onError={handleError}
            onReset={handleReset}>
            {props.children}
        </ReactErrorBoundary>
    );
}

export default ErrorBoundary;