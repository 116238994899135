import { useTranslation } from "react-i18next";

export namespace MyBooking {
  interface Props {
    totalPrice: number,
    vat: number,
    lang: string;
    currency: string
  }

  export function TripTotal({
    lang, totalPrice, vat, currency
  }: Props) {
    const { t, i18n } = useTranslation();

    return (
      <>
        <div className="w-full flex flex-row-reverse">
        <div className="my-6 grid grid-cols-[1fr_auto] gap-x-4 gap-y-2">
          <p className="h-[24px] text-right font-semibold text-gray-700">
            {t("amount", { ns: "common" })}:
          </p>
          <p className="h-[24px] text-right text-gray-500">
            {new Intl.NumberFormat(lang, {
              style: "currency",
              currency: currency,
            }).format(totalPrice - totalPrice * vat)}
          </p>

          <p className="h-[24px] text-right font-semibold text-gray-700">
            {t("vat", { ns: "common" })}:
          </p>
          <p className="h-[24px] text-right text-gray-500">
            {new Intl.NumberFormat(lang, {
              style: "currency",
              currency: currency,
            }).format(totalPrice * vat)}
          </p>

          <p className="h-[24px] text-right font-semibold text-gray-700">
            {t("orderTotal", { ns: "common" })}:
          </p>
          <p className="h-[24px] text-right text-gray-500">
            {new Intl.NumberFormat(lang, {
              style: "currency",
              currency: currency,
            }).format(totalPrice)}
          </p>
        </div>
      </div>
      </>
    );
  }
}
