import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { TrashIcon } from "@heroicons/react/24/outline";
import { toast } from "react-toastify";

export namespace MyBooking {
  interface PassengerTripInfoProp {
    trip: Trip | undefined;
    lang: string;
    readOnly: boolean;
    onCancel: (id: string, allPassenger: boolean) => void;
  }

  export function PassengerTripInfo({
    trip,
    lang,
    readOnly,
    onCancel,
  }: PassengerTripInfoProp) {
    const { t, i18n } = useTranslation();
    const [isCancelling, setIsCancelling] = useState<boolean>(false);
    const [id, setId] = useState<string>('');

    useEffect(() => {
      const handleCancel = async () => {
      if(!isCancelling || id == '')
        return;
      
        try {
          await onCancel(id, false);
          toast.dismiss();
        } catch (error) {
          console.error('Error while cancelling:', error);
        } finally {
          setId('');
          setIsCancelling(false);
        }
      };

      handleCancel();
    }, [isCancelling]);

    function ModalDisplay({ waiting, segmentid } : {waiting : boolean, segmentid : string}) {
      const [isWaiting, setIsWaiting] = useState<boolean>(waiting);
      return <>
      <p className="w-full text-center font-medium text-gray-700">{t("confirm.cancel_passenger")}</p>
      <div className="pt-4 flex justify-end">
        <button onClick={() => toast.dismiss()} className={`px-4 py-1 rounded hover:bg-gray-600/20 ${isWaiting ? 'cursor-not-allowed opacity-20' : ''}`} disabled={isWaiting}>
          {t("close", { ns: "common" })}
        </button>
        <button
          className={`text-red-500 px-4 py-1 rounded hover:bg-gray-600/20 ${isWaiting ? 'cursor-not-allowed' : ''}`}
          disabled={isWaiting}
          onClick={async () => {
            console.log('Button clicked. ' + isWaiting)
            if (isWaiting) {
              return;
            }

            setIsWaiting(true);
            setId(segmentid);
            setIsCancelling(true);
          }}>
          {isWaiting ? 
          <div className="w-5 h-5 rounded-full animate-spin border-2 border-solid border-gray-500 border-t-transparent"></div>
          : 
          t("confirm", { ns: "common" })}
        </button>
      </div>
    </>;
    }

    const handleCancelPassengerClick = (id: string) => {
      toast(
        <ModalDisplay waiting={isCancelling} segmentid={id} />,
        {
          type: 'info',
          position: toast.POSITION.TOP_CENTER,
          autoClose: false,
          closeOnClick: false,
          draggable: false,
          closeButton: false,
          toastId: 'toast'
        }
      );
    }

    return (
      <>
        <div className="my-6">
          <h1 className="text-xl font-semibold text-gray-800">
            {trip?.startName} - {trip?.endName}
          </h1>

          <div className="grid grid-cols-[80px_1fr] md:grid-cols-[200px_120px_250px_auto] pt-6">
            <div className="font-semibold text-gray-700 order-1 md:order-1">
              {t("date", { ns: "common" })}:
            </div>
            <div className="font-semibold text-gray-700 order-3 md:order-2">
              {trip?.type === "FLIGHT" ? t("airplane", { ns: "translation" }) : t("train", { ns: "translation" })}:
            </div>
            <div className="font-semibold text-gray-700 order-5 md:order-3">
              {t("email", { ns: "common" })}:
            </div>
            <div className="font-semibold text-gray-700 order-7 md:order-4">
              {t("phone", { ns: "common" })}:
            </div>

            <div className="text-gray-500 order-2 md:order-5">
              {new Date(trip?.pickupTime ?? "").toLocaleString(undefined, { timeZone: 'Europe/Stockholm' })}
            </div>
            <div className="text-gray-500 order-4 md:order-6">
              {trip?.number ?? ""}
            </div>
            <div className="text-gray-500 order-6 md:order-7">
              {trip?.passengers[0]?.email ?? ""}
            </div>
            <div className="text-gray-500 order-8 md:order-8">
              {trip?.passengers[0]?.phone ?? ""}
            </div>
          </div>
        </div>

        <div className="flex flex-col mb-10">
          <div className="font-semibold text-gray-700 pr-3">
            {t("passenger", { count: trip?.passengers.length, ns: "common" })}:
          </div>

          {trip?.passengers.map((item, i) => (
            <div
              key={i.toString()}
              className="grid grid-cols-[1fr_120px_auto] md:grid-cols-[120px_120px_auto] items-center"
            >
              <p
                className={
                  !item.cancelled
                    ? "h-[24px] text-gray-500 truncate"
                    : "h-[24px] text-gray-400 line-through truncate"
                }
              >
                {item.firstName + " " + item.lastName}
              </p>
              <p
                className={
                  !item.cancelled
                    ? "h-[24px] text-gray-500"
                    : "h-[24px] text-gray-400 line-through"
                }
              >
                {new Intl.NumberFormat(lang, {
                  style: "currency",
                  currency: "SEK",
                }).format(item.price)}
              </p>
              {!readOnly && !item.cancelled && (
                <TrashIcon
                  onClick={() => handleCancelPassengerClick(item.id)}
                  className={`w-5 ${isCancelling ? 'text-gray-300 cursor-not-allowed' : 'text-red-300 hover:text-red-500 hover:cursor-pointer hover:font-bold '}`}
                />
              )}
            </div>
          ))}
        </div>
      </>
    );
  }

  export interface Passenger {
    id: string;
    firstName: string;
    lastName: string;
    email: string;
    phone: string;
    isAdult: boolean;
    price: number;
    cancelled: boolean;
  }

  export interface Trip {
    type: string;
    number: string;
    dropoffTime: string;
    dropoffLocationCode: string;
    pickupTime: string;
    pickupLocationCode: string;
    startCode: string;
    startName: string;
    endCode: string;
    endName: string;
    passengers: Passenger[];
  }
}
