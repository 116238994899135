import { Fragment, SyntheticEvent, useEffect, useState } from 'react'
import { Combobox, Transition } from '@headlessui/react'
import { BookingApi } from '../../services/Booking/type';
import { t } from 'i18next';
import { PickerDetails } from '../../types/booking';
import { useTranslation } from 'react-i18next';
import { utcTimeToSwedish } from '../../Utils/DateUtil';

interface FlightPickerProperties {
  flights: BookingApi.Transportation[];
  value: PickerDetails;
  onChange: (details: PickerDetails) => void;
}

export default function FlightPicker({ flights, value: inputValue, onChange }: FlightPickerProperties) {

  const [selected, setSelected] = useState<BookingApi.Transportation | null>(flights.find(f => f.number == inputValue.number) ?? null);
  const [query, setQuery] = useState<string>(flights.find(f => f.number == inputValue.number) !== null ? inputValue.number : '');
  const [manualMode, setManualMode] = useState<boolean>(false);
  const [inited, setInited] = useState<boolean>(false);
  const { t, i18n } = useTranslation();


  const handleSetSelected = (value: BookingApi.Transportation) => {
    setSelected(value);

    const fd: PickerDetails = { ...inputValue ,
      number: value.number, 
      time: inputValue.direction === "A" ? value.arrivalDate.slice(11,16) : value.departureDate.slice(11,16),
      connectionCode : inputValue.direction === "A" ?  value.arrivalCode : value.departureCode,
     };
    onChange(fd)
  };

  const manualSetSelected = (event: SyntheticEvent<HTMLInputElement>) => {
    
    let t = "12:00";

    if(event.currentTarget.type === 'time'){
      t = event.currentTarget.value;
    }
    
    const f: BookingApi.Transportation = {
      type: 'FLIGHT',
      headSign: undefined,
      number: event.currentTarget.type === 'text' ? event.currentTarget.value : selected?.number ?? "",
      departureDate: `${inputValue.date}T${t}Z`, 
      departureCode: inputValue.connectionCode,
      departureName: '',
      arrivalDate: `${inputValue.date}T${t}Z`, 
      arrivalCode: inputValue.connectionCode,
      arrivalName: '',
      international: false,
      carrierName: ''
    };
    handleSetSelected(f);
  };

  const transitionToManualAndSetSelected = () => {
    setManualMode(true);
    manualSetSelected(({ currentTarget: { value: query, type: 'text' } } as SyntheticEvent<HTMLInputElement>));
  };


  useEffect(() => {

    if (manualMode) {
      setQuery('');
      return;
    }
  }, [inputValue]);


  useEffect(() => {

    setQuery('');

    if (inited === false && flights?.length > 1) {
      let f = flights.find(f => f.number == inputValue?.number) ?? null;
      setManualMode(flights?.length < 1 || (!f && inputValue?.number?.length > 0) ? true : false);
      if(!f){
        f = {
          type: '',
          headSign: undefined,
          number: inputValue?.number,
          departureDate: "11:22",
          departureCode: '',
          departureName: '',
          arrivalDate: "11:22",
          arrivalCode: '',
          arrivalName: '',
          international: false,
          carrierName: ''
        }
      }
      setQuery(inputValue.number);
      setSelected(f);
      setInited(true);
    }
    else{
      setSelected(null);
      if(inputValue.date !== undefined && flights?.length === 0){
        setManualMode(true);
      }
    }

  }, [flights]);

  const filteredFlights =
    query === ''
      ? flights.sort((a, b) => new Date(a.arrivalDate) > new Date(b.arrivalDate) ? 1 : -1)
      : flights.filter((f) =>
        f.number
          .toLowerCase()
          .replace(/\s+/g, '')
          .includes(query.toLowerCase().replace(/\s+/g, ''))
      ).sort((a, b) => new Date(a.arrivalDate) > new Date(b.arrivalDate) ? 1 : -1);

  const id = `input_${Math.random().toString(36).substring(2, 10)}`;

  return (
    <>
      {!manualMode ? (
        <Combobox value={selected} by="number" onChange={handleSetSelected} disabled={flights?.length < 1}>
          {({ open }) => (
            <div className="relative w-full">

              <label className="text-gray-700 hover:cursor-pointer" htmlFor={id}>{t("flight", { ns: "common" })}</label>
              <div id={id} className="relative w-full cursor-default mt-2 text-gray-700 bg-white ">
                <Combobox.Input
                  className="w-full py-2 pl-3 pr-10 text-gray-900 border border-gray-200 rounded-md focus:border-blue-400 focus:ring-blue-300 focus:ring-opacity-40 focus:outline-none focus:ring appearance-none"
                  displayValue={(flight: BookingApi.Transportation) => flight?.number}
                  onChange={(event) => setQuery(event.target.value)}
                  onClick={(e: any) => {
                    if (e.relatedTarget?.id?.includes('headlessui-combobox-button')) return;
                    !open && e.target.nextSibling.click();
                  }}
                />
                <Combobox.Button className="absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
                  <svg className="fill-current h-4 w-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><path d="M10 12l-5-5 1.41-1.41L10 9.18l3.59-3.59L15 7l-5 5z" /></svg>
                </Combobox.Button>
              </div>

              {open && (
                <Transition
                  // as={Fragment}
                  leave="transition ease-in duration-100"
                  leaveFrom="opacity-100"
                  leaveTo="opacity-0"
                  afterLeave={() => setQuery('')}>

                  <Combobox.Options className="absolute mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm z-10">
                    {filteredFlights.length === 0 && query !== '' ? (
                      <>
                        <div className="relative cursor-default select-none py-2 px-4 text-gray-700">
                        {t('nothingfound', {ns: 'common'})}
                        </div>
                        <div className="relative cursor-default select-none py-2 px-4 text-gray-700 hover:bg-gray-100 hover:cursor-pointer" onClick={transitionToManualAndSetSelected}>
                          {t('canFind?', {ns: 'common'})}  
                        </div>
                      </>
                    ) : (
                      filteredFlights.map((flight) => (
                        <Combobox.Option key={flight.number} className={({ active }) => `relative cursor-default select-none py-2 px-4 text-sm text-gray-700 ${active ? "bg-gray-100" : ""}`}
                          value={flight}>
                          <span className={'`block truncate'}>
                            {`${inputValue.direction === "A" ? utcTimeToSwedish(flight.arrivalDate)?.slice(11,16) : utcTimeToSwedish(flight.departureDate)?.slice(11,16)} - ${flight.number}`}
                          </span>
                        </Combobox.Option>
                      ))
                    )}
                  </Combobox.Options>
                </Transition>
              )}
            </div>
          )}
        </Combobox>
      )
        :
        (
          <div className='w-full flex gap-6'>
            <div className='w-full'>
              <label htmlFor={`m${id}`} className="text-gray-700 hover:cursor-pointer">{t("flight", { ns: 'common' })}</label>
              <input type="text" id={`m${id}`} className="block w-full px-4 py-2 mt-2 text-gray-700 bg-white border border-gray-200 rounded-md focus:border-blue-400 focus:ring-blue-300 focus:ring-opacity-40 focus:outline-none focus:ring" placeholder={t("flight", { ns: 'common' }) ?? ""} defaultValue={query} onBlur={manualSetSelected} />
            </div>
            <div className='w-full'>
              <label htmlFor="time" className="text-gray-700 hover:cursor-pointer">{t("time", { ns: 'common' })}</label>
              <input type="time" id="time" name="time" className="block w-full px-4 py-2 mt-2 text-gray-700 bg-white border border-gray-200 rounded-md focus:border-blue-400 focus:ring-blue-300 focus:ring-opacity-40 focus:outline-none focus:ring" 
                defaultValue={utcTimeToSwedish(`${inputValue.date}T${inputValue.time}:00`)?.slice(11,16)} onBlur={manualSetSelected} />
            </div>
          </div>
        )
      }
    </>
  )
}
