import { RuntimeEnvVars, EnvVars } from '../types/global';
import V from "./version.json";

const runtime: RuntimeEnvVars = window.__ENV__;

const constants: EnvVars = {
  ...runtime,
  Version: V,
};

export default constants;
