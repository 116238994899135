export namespace BookingApi {
  
  export interface BootData {
    window?: TransferWindow
    valid: boolean
  }
  
  export interface TransferSite {
    code: string;
    open_from: Date;
    open_to: Date;
    name: string;
    img: string;
    logo: string;
  }
  
  export interface TransferWindow {
    from: string | undefined;
    to: string | undefined;
  }

  export interface ServiceLocation {
    name: string;
    serviceName: string;
    locationCode: string;
    productName: string;
    productCode: string;
    latitude: number;
    longitude: number;
    distance: string;
    duration: number;
    description: any;
    externalLocationCode: string;

  }

  export interface ConnectionType {
    connectiontype: string;
  }


  export interface Transportation {
    type: string;
    headSign: any;
    number: string;
    departureDate: string;
    departureCode: string;
    departureName: string;
    arrivalDate: string;
    arrivalCode: string;
    arrivalName: string;
    international: boolean;
    carrierName: string;
  }

  export interface Availability {
    startNode: Node;
    endNode: Node;
    product: Product;
    price: Price;
  }

  export interface Node {
    street: Street;
    time: string;
  }

  export interface Street {
    location: StreetLocation;
    latitude: number;
    longitude: number;
    description: string;
  }

  export interface StreetLocation {
    description: string;
    code: string;
    name: string;
  }

  export interface Product {
    code: string;
    name: string;
    description: any;
    maxPassengers: number;
    childDiscountPercent: number;
    pickupTimeLocked: boolean;
    childAgeRequired: boolean;
    numberOfPassengersRequired: boolean;
    connectionRequired: boolean;
    foidRequired: boolean;
    emailRequired: boolean;
    firstPossibleBookingTime: string;
    latestCancelTime: string;
    serviceName: any;
    serviceNameEnglish: any;
  }

  export interface Price {
    priceListOrganizationId: number;
    priceListName: string;
    includingVat: string;
    excludingVat: string;
    vatPercent: string;
    currencyCode: string;
  }

  export enum Currency {
    SEK = "SEK",
    DKK = "DKK",
  }

  export interface InitBookingRequest {
    productCode: string;
    passengers: Passenger[];
    trips: Trip[];
    correlationId : string
    clientName: string
    amount: number;
    language: string;
    currency: Currency;
  }
  

  export interface PreBookWithSwePayResponse {
    swepayId: string | undefined;
    seamlessHref: string | undefined;
    correlationId : string
  }

  export type FinalizeBookingWithSwePayRequest = {
    swepayId: string;
    bookingRef: string;
    productCode: string;
    passengers: Passenger[];
    trips: Trip[];
    language: string;
    correlationId: string;
    clientName: string
  };

  export type CancelPassengerRequest = {
    ftresid: string;
    bookingRef: string;
    language: string;
  };

  export type CancelBookingRequest = {
    email: string;
    bookingRef: string; 
    language: string
  };

  export interface Passenger {
    firstName: string;
    lastName: string;
    email: string;
    phone: string;
    isBillingPassenger: boolean;
    childAge: number | undefined;
    infoToDriver?: string;
  }

  export interface Trip {
    startCode: string;
    startTime: string;
    endCode: string;
    endTime: string;
    connection: TripConnection;
  }

  export interface TripConnection {
    number: string;
    depatureDate: string;
    depatureCode: string;
    arrivalDate: string;
    arrivalCode: string;
    type: string
  }

  export interface Segment {
    segmentId: number;
    bookingId: number;
    bookingReference: string;
    creatorName: string;
    version: number;
    cancelled: boolean;
    ftresid: string;
    bookingDate: string;
    routeDistanceMeters: any;
    routeDurationSeconds: number;
    responsible: any;
    status: string;
    infoToDriver: any;
    clientName: string;
    comment: any;
    passenger: Passenger;
    product: Product;
    connection: Connection;
    startNode: Node;
    endNode: Node;
    customerPrice: Price;
    feePrice: any;
    refundPrice: Price;
    references: any;
    remoteAddress: string;
    modifier: string;
    latestCancelTime: string;
  }

  export interface Connection {
    type: string;
    number: string;
    international: boolean;
    departureDate: string;
    departureCode: string;
    arrivalDate: string;
    arrivalCode: string;
  }

  export interface BookingValidationResponse {
    bookingReference: string;
    status: number;
  }
  
  export interface CarouselImage {
    url: string;
    alt?: string | null;
    text?: string | null;
  }
  
  export interface TransferLogoResponse {
    url: string | null;
  }
}
