import { useEffect, useState, useRef } from 'react'
import { useAppDispatch } from '../../app/hooks';
import { BOOKING_STATUS, setStatus } from "./bookingSlice";
import { useTranslation } from 'react-i18next';

interface TimeoutCountdownProperties {
  time: number;
  interval?: number;
  cancelHandler?: () => void;
}

function TimeoutCountdown({ time, interval = 1000, cancelHandler }: TimeoutCountdownProperties) {
  const [timeRemaining, setTimeRemaining] = useState<number>(time);
  const [showModal, setShowModal] = useState<boolean>(false);
  const timePassedElement = useRef<HTMLDivElement>(null);
  const dispatch = useAppDispatch();
  const { t, i18n } = useTranslation();

  useEffect(() => {
    const timer = setInterval(() => {
      setTimeRemaining((t) => {
        const newTime = t - interval;
        const newPercentage = Math.max(newTime / time, 0);

        updateUI(newPercentage);

        if (newPercentage <= 0) {
          clearInterval(timer);
          setShowModal(true);
        }

        return newTime;
      });
    }, interval);

    return () => clearInterval(timer);
  }, []);

  function updateUI(percentage: number) {

    var angle = 360 * (1 - percentage);
    var x = 50 * Math.sin(Math.PI * 2 * angle / 360);
    var y = 50 * Math.cos(Math.PI * 2 * angle / 360);

    var pathStops = "100% 100%";

    if (angle > 90)
      pathStops = pathStops + ", 100% 0%"

    if (angle > 180)
      pathStops = pathStops + ", 0% 0%"

    if (angle > 270)
      pathStops = pathStops + ", 0% 100%"

    var path = `polygon(50% 50%, 50% 100%, ${pathStops}, ${50 + (x)}% ${50 + (y)}%, 50% 50%)`;

    if (timePassedElement.current != null)
      timePassedElement.current.style.clipPath = path;
  }

  function formatTime(t: number) {
    t = Math.ceil(t / 1000);
    const minutes = Math.floor(t / 60);
    const seconds = t % 60;
    return `${minutes}:${(seconds < 10) ? `0${seconds}` : seconds}`;
  }

  async function cancel() {
    setShowModal(false);
    if(cancelHandler)
      cancelHandler();

    dispatch(setStatus(BOOKING_STATUS.INIT));
  }
  async function search() {
    setShowModal(false);
    if(cancelHandler)
      cancelHandler();
  }

  return (
    <>
      <div className="flex items-center justify-between w-16" data-tooltip={t("bookingTimeout.toolTip", { ns: "translation" })}>
        <div className="relative w-6 h-6 rounded-full bg-ft-blue-dark overflow-hidden" style={{ maskImage: "radial-gradient(circle, white 100%, black 100%)" }}>
          <div id="time-passed" className="absolute top-0 left-0 w-full h-full bg-white opacity-80 transform -rotate-180" ref={timePassedElement}></div>
        </div>
        <span id="time" className="h-[24px] text-gray-500">{formatTime(timeRemaining)}</span>
      </div>

      {showModal && (
        <>
          <div className="fixed inset-0 bg-gray-500 opacity-75 z-20"></div>

          <div className="fixed z-30 inset-0 overflow-y-auto transition ease-out duration-300 transform ">
            <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:items-center sm:p-0">


              <div className="w-full inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full" role="dialog">
                <div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">


                  <div className="sm:flex sm:items-start">
                    <div className="mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10">


                      <svg width="64px" height="64px" className="h-6 w-6 text-red-600" fill="none" viewBox="0 0 24.00 24.00" xmlns="http://www.w3.org/2000/svg" stroke="#ef4444" strokeWidth="0.45600000000000007">
                        <g id="SVGRepo_bgCarrier" strokeWidth="0"></g>
                        <g id="SVGRepo_tracerCarrier" strokeLinecap="round" strokeLinejoin="round"></g>
                        <g id="SVGRepo_iconCarrier">
                          <path d="M12 7.25C12.4142 7.25 12.75 7.58579 12.75 8V13C12.75 13.4142 12.4142 13.75 12 13.75C11.5858 13.75 11.25 13.4142 11.25 13V8C11.25 7.58579 11.5858 7.25 12 7.25Z" fill="#ef4444"></path>
                          <path d="M12 17C12.5523 17 13 16.5523 13 16C13 15.4477 12.5523 15 12 15C11.4477 15 11 15.4477 11 16C11 16.5523 11.4477 17 12 17Z" fill="#ef4444"></path>
                          <path fillRule="evenodd" clipRule="evenodd" d="M8.2944 4.47643C9.36631 3.11493 10.5018 2.25 12 2.25C13.4981 2.25 14.6336 3.11493 15.7056 4.47643C16.7598 5.81544 17.8769 7.79622 19.3063 10.3305L19.7418 11.1027C20.9234 13.1976 21.8566 14.8523 22.3468 16.1804C22.8478 17.5376 22.9668 18.7699 22.209 19.8569C21.4736 20.9118 20.2466 21.3434 18.6991 21.5471C17.1576 21.75 15.0845 21.75 12.4248 21.75H11.5752C8.91552 21.75 6.84239 21.75 5.30082 21.5471C3.75331 21.3434 2.52637 20.9118 1.79099 19.8569C1.03318 18.7699 1.15218 17.5376 1.65314 16.1804C2.14334 14.8523 3.07658 13.1977 4.25818 11.1027L4.69361 10.3307C6.123 7.79629 7.24019 5.81547 8.2944 4.47643ZM9.47297 5.40432C8.49896 6.64148 7.43704 8.51988 5.96495 11.1299L5.60129 11.7747C4.37507 13.9488 3.50368 15.4986 3.06034 16.6998C2.6227 17.8855 2.68338 18.5141 3.02148 18.9991C3.38202 19.5163 4.05873 19.8706 5.49659 20.0599C6.92858 20.2484 8.9026 20.25 11.6363 20.25H12.3636C15.0974 20.25 17.0714 20.2484 18.5034 20.0599C19.9412 19.8706 20.6179 19.5163 20.9785 18.9991C21.3166 18.5141 21.3773 17.8855 20.9396 16.6998C20.4963 15.4986 19.6249 13.9488 18.3987 11.7747L18.035 11.1299C16.5629 8.51987 15.501 6.64148 14.527 5.40431C13.562 4.17865 12.8126 3.75 12 3.75C11.1874 3.75 10.4379 4.17865 9.47297 5.40432Z" fill="#ef4444"></path>
                        </g>
                      </svg>
                    </div>
                    <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                      <h3 className="text-lg leading-6 font-medium text-gray-900" id="modal-headline">{t("bookingTimeout.modalTitle", { ns: "translation" })}</h3>
                      <div className="mt-2">
                        <p className="text-sm text-gray-500">{t("bookingTimeout.modalBody", { ns: "translation" })}</p>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="bg-gray-50 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
                  <button onClick={search} type="button" className="w-full inline-flex justify-center px-4 py-2 bg-ft-blue-dark text-base font-medium text-white hover:pointer sm:ml-3 sm:w-auto sm:text-sm">{t("bookingTimeout.modalOk", { ns: "translation" })}</button>
                  <button onClick={cancel} type="button" className="mt-3 w-full inline-flex justify-center border border-gray-300 px-4 py-2 bg-white text-base font-medium text-gray-700 hover:pointer sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm">{t("bookingTimeout.modalCancel", { ns: "translation" })}</button>
                </div>

              </div>
            </div>
          </div>
        </>
      )}

    </>
  );
};

export default TimeoutCountdown;