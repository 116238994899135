import { Fragment, SyntheticEvent, useEffect, useRef, useState } from 'react'
import { t } from 'i18next';
import { PickerDetails } from '../../types/booking';
import {
  getTransportation
} from "../../services/Booking/api";
import { BOOKING_DIRECTION } from './bookingSlice';
import { utcTimeToSwedish } from '../../Utils/DateUtil';

interface TrainPickerProperties {
  value: PickerDetails;
  onChange: (details: PickerDetails) => void;
}

export default function TrainPicker({ value: inputValue, onChange }: TrainPickerProperties) {

  const { date, connectionCode, direction } = inputValue;
  const [missingTrain, setMissingTrain] = useState<boolean>(false)
  const timeInputRef = useRef<HTMLInputElement>(null);
  const numInputRef = useRef<HTMLInputElement>(null);


  const manualSetSelected = (pd: PickerDetails) => {
    onChange(pd);
  };

  const setNumberHandler = async (event: SyntheticEvent<HTMLInputElement>) => {
    
    // GET TRAIN FROM API
    if(!date || event.currentTarget.value === ""){
      return;
    }

    const transports = await getTransportation(date ?? "", direction, "TRAIN", connectionCode, event.currentTarget.value)

    const apiResponse = transports.length > 0;
    setMissingTrain(!apiResponse);

    if(!apiResponse){
      manualSetSelected({ ...inputValue, 
        time: inputValue.time, 
        number: numInputRef.current?.value ?? ""
      });
      return;
    }

    // SET THE TIME FROM API RESPONSE
    // 2023-05-19T15:06:00+02:00


    manualSetSelected({ ...inputValue, 
      time: direction === 'A' ? transports[0].arrivalDate.substring(11,16) : transports[0].departureDate.substring(11,16), 
      number: numInputRef.current?.value ?? inputValue.number
    });
  };

  const setTimeHandler = (event: SyntheticEvent<HTMLInputElement>) => {
    manualSetSelected({...inputValue, time: event.currentTarget.value, number: numInputRef.current?.value ?? inputValue.number});
  };

   useEffect(() => {
     if (timeInputRef.current && missingTrain && !/^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}Z$/.test(timeInputRef.current.value ?? "")) {
       timeInputRef.current.focus();
     }
   }, [missingTrain]);

   useEffect(() => {
    if(inputValue.number === numInputRef.current?.value) return;
     setNumberHandler({ currentTarget: { value: inputValue.number } } as SyntheticEvent<HTMLInputElement>);
   }, [inputValue]);

  const id = `input_${Math.random().toString(36).substring(2, 10)}`;

  return (
    <>
    { inputValue.date &&
      <div className='w-full flex gap-6'>
        <div className='w-full'>
          <label htmlFor={`m${id}`} className="text-gray-700 hover:cursor-pointer">{t("train")}</label>
          <input ref={numInputRef} type="text" id={`m${id}`} className="block w-full px-4 py-2 mt-2 text-gray-700 bg-white border border-gray-200 rounded-md focus:border-blue-400 focus:ring-blue-300 focus:ring-opacity-40 focus:outline-none focus:ring" placeholder={t("train") ?? ""} defaultValue={inputValue.number} onBlur={setNumberHandler} />
        </div>
        {
          missingTrain ?
            (
              <div className='w-full'>
                <label htmlFor="time" className="text-gray-700 hover:cursor-pointer">{t("time", { ns: 'common' })}</label>
                <input ref={timeInputRef} type="time" id="time" name="time" className="block w-full px-4 py-2 mt-2 text-gray-700 bg-white border border-gray-200 rounded-md focus:border-blue-400 focus:ring-blue-300 focus:ring-opacity-40 focus:outline-none focus:ring" 
                defaultValue={utcTimeToSwedish(`${inputValue.date}T${inputValue.time}:00`)?.slice(11,16)} onBlur={setTimeHandler} />
              </div>
            ) : null
        }
      </div>
    }
    </>
  )
}
