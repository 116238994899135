import { useEffect, useState } from "react";
import {
  cancelMyBooking,
  cancelPassenger,
  getMyBooking,
} from "../../services/Booking/api";
import { BookingApi } from "../../services/Booking/type";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import Skeleton from "react-loading-skeleton";
import { Convert } from "../../Utils/LangConverter";
import { useTranslation } from "react-i18next";
import { MyBooking } from "./PassengerTripInfo";
import { MyBooking as MyBookingTotal } from "./TripTotal";


interface OverviewProperties {
  bookingSegments: BookingApi.Segment[];
}

export default function Overview({ bookingSegments }: OverviewProperties) {
  const { t, i18n } = useTranslation();

  const [trips, setTrips] = useState<(MyBooking.Trip|undefined)[]>([]);
  const [segments, setSegments] = useState<BookingApi.Segment[]>([]);


    const cancelSegmentOnBooking = async (ftresid: string) => {
      const segment = segments.find((s) => s.ftresid === ftresid);
      if (segment === undefined) return;
      const response = await cancelPassenger(
        segment.bookingReference,
        segment.ftresid,
        i18n.language
      );
      
      if (!response.ok) {
        if (response.status === 403) {
          toast.warn(t("toast.err_cancelDueDatePassed"));
        }
        return;
      }

      if (response.status === 202) {
        toast.warn(t("toast.err_cancelNoCredit"), {
          autoClose: false, // This will make the toast sticky until the user closes it manually
        });
      }


      const index = segments.findIndex(s => s.ftresid === ftresid);
      if(index !== -1) {
        segments[index].cancelled = true;
        toast.info(t('toast.info_passengerCancelled', { name: `${segments[index].passenger.firstName}` }));
      }
      
      MapEntities(segments);
    };
    

    const cancelBookingOnServer = async (id: string, email: string) => {
      const response = await cancelMyBooking(email, id, i18n.language);
      
      if (!response.ok) {
        if (response.status === 403) {
          toast.warn(t("toast.err_cancelDueDatePassed"));
        }
        return;
      }
      
      toast.info(t("toast.info_bookingIscancelled"));
      segments.forEach(segment => {
        segment.cancelled = true;
      });

      MapEntities(segments);
    };


useEffect(()=> {
  MapEntities(bookingSegments);
},[])

 

const MapEntities = (segments :BookingApi.Segment[]) => {
    const bookingTrips: MyBooking.Trip[] = segments.slice().map((s: BookingApi.Segment) => ({
      type: s.connection.type,
      number: s.connection.number,
      dropoffTime: s.endNode.time,
      dropoffLocationCode: s.connection.departureCode,
      pickupTime: s.startNode.time,
      pickupLocationCode: s.connection.arrivalCode,
      startCode: s.startNode.street.location.code,
      startName: s.startNode.street.location.name,
      endCode: s.endNode.street.location.code,
      endName: s.endNode.street.location.name,
      passengers: segments.filter(p => p.connection.arrivalCode === s.connection.arrivalCode).map((s: BookingApi.Segment) => ({
        firstName: s.passenger.firstName,
        lastName: s.passenger.lastName,
        email: s.passenger.email,
        phone: s.passenger.phone,
        isAdult: s.passenger.childAge === undefined,
        price: parseFloat(s.customerPrice.includingVat),
        cancelled: s.cancelled,
        id: s.ftresid
      }))
    }));

    const distinctTrips = Array.from(
      new Set(bookingTrips.map((item) => item.dropoffLocationCode))
    ).map((name) => {
      return bookingTrips.find((item) => item.dropoffLocationCode === name);
    });

    setTrips(distinctTrips);
    setSegments(segments);
}



  const calculatePrice =() => {
    return trips.reduce((acc, trip) => {
      return acc + (trip === undefined ? 0:  trip.passengers
        .filter(passenger => !passenger.cancelled)
        .reduce((acc2, passenger) => {
          return acc2 + passenger.price;
        }, 0));
    }, 0);
  }
 
  const onCancelCallback = async (id: string, booking: boolean) => {
    if(booking){
      if (segments && segments[0] && segments[0].bookingReference && segments[0].passenger && segments[0].passenger.email) {
        await cancelBookingOnServer(segments[0].bookingReference, segments[0].passenger.email);
    }
    }
    else{
      const segmentToCancel = segments.find((s) => s.ftresid === id);
      if (segmentToCancel && segmentToCancel.ftresid) {
        await cancelSegmentOnBooking(segmentToCancel.ftresid);
    }
    }
  }

  
  const vat = 0.06;
  const lang = Convert(i18n.language);

  return (
    <>
      {trips.map((item) => (
        <div key={item?.pickupLocationCode}>
          <div className="">
            <MyBooking.PassengerTripInfo
              trip={item}
              lang={Convert(i18n.language)}
              readOnly={false}
              onCancel={onCancelCallback}
            />
          </div>
        </div>
      ))}

      <MyBookingTotal.TripTotal currency="SEK" lang={lang} vat={vat} totalPrice={calculatePrice()} />
      
    </>
  );
}
