import { Convert } from "../../Utils/LangConverter";
import { Passenger } from "../../components/Booking/bookingSlice";
import { ApiResponse } from "../../types/api";
import { BookingApi } from "./type";
import constants from "../../constants/constants";
import { BOOKING_TYPE } from "../../types/booking";
import fetchWithCustomHeader from "../FetchUtil";

export async function getBoot(
  showBoundary: (error: Error) => void
): Promise<BookingApi.BootData> {
  try {
    const response = await fetchWithCustomHeader(
      `${constants.Api.API_URL}/transfer/boot`
    );

    if (response.ok) {
      return await response.json();
    }

    if (!response.ok && response.status !== 404) {
      throw Error("Getting boot data failed!");
    }
   
  } catch (error: any) {
    showBoundary(error);
  }

  return {
    window: undefined,
    valid: false
  };
}

export async function getTransferWindow(
  showBoundary: (error: Error) => void
): Promise<BookingApi.TransferWindow> {
  try {
    const response = await fetchWithCustomHeader(
      `${constants.Api.API_URL}/transfer/window`
    );

    if (!response.ok) {
      throw Error("Getting transfer window failed!");
    }
    return await response.json();
  } catch (error: any) {
    showBoundary(error);
  }

  return {
    from: undefined,
    to: undefined,
  };
}

export async function getStartLocations(
  type: BOOKING_TYPE
): Promise<Array<BookingApi.ServiceLocation>> {

  const response = await fetchWithCustomHeader(
    `${constants.Api.API_URL}/locations/startLocations?bookingtype=${bookingTypeToString(type)}`
  );

  if (!response.ok) {
    return [];
  }
  return await response.json();
}

export async function getConnectionTypes(): Promise<Array<BookingApi.ConnectionType>> {

  const response = await fetchWithCustomHeader(
    `${constants.Api.API_URL}/transfer/connectionTypes`
  );

  if (!response.ok) {
    return [];
  }
  return await response.json();
}

function bookingTypeToString(type: BOOKING_TYPE): string {
  switch (type) {
    case BOOKING_TYPE.FLIGHT:
      return 'flight';
    case BOOKING_TYPE.TRAIN:
      return 'train';
    case BOOKING_TYPE.MYBOOKING:
      return 'mybooking';
    default:
      return ''; // Handle unknown values if needed
  }
}

export async function getLocations(
  date: string,
  connectionCode: string,
  direction: string
): Promise<Array<BookingApi.ServiceLocation>> {
  const response = await fetchWithCustomHeader(
    `${constants.Api.API_URL}/ftbook/servicelocation?date=${date}&connectionCode=${connectionCode}&direction=${direction}&serviceName=Transfer`
  );

  if (!response.ok) {
    return [];
  }
  return await response.json();
}

export async function getTransportation(
  date: string,
  direction: string = "A",
  connectionType: string = "FLIGHT",
  connectionCode: string = "OSD",
  number: string = ""
): Promise<BookingApi.Transportation[]> {
  let num = "";
  if (number !== "") {
    num = `&number=${number}`;
  }

  const response = await fetchWithCustomHeader(
    `${constants.Api.API_URL}/ftbook/timetable?date=${date}&connectionType=${connectionType}&direction=${direction}&connectionCode=${connectionCode}${num}`
  );
  if (!response.ok) {
    return [];
  }
  return await response.json();
}

export async function getAvailability(
  date: string,
  start: string,
  end: string
): Promise<Array<BookingApi.Availability>> {
  const response = await fetchWithCustomHeader(
    `${constants.Api.API_URL}/ftbook/availability?startLocationCode=${start}&endLocationCode=${end}&connectionDate=${date}`
  );

  if (!response.ok) {
    return [];
  }
  return await response.json();
}

export async function getMyBooking(
  id: string,
  email: string
): Promise<Array<BookingApi.Segment> | number> {
  const response = await fetchWithCustomHeader(
    `${constants.Api.API_URL}/ftbook/mybooking/${id}?email=${email}`
  );

  if (!response.ok) {
    return response.status;
  }
  return await response.json();
}

export async function initializePayment(
  amount: number,
  passengers: BookingApi.Passenger[],
  trips: BookingApi.Trip[],
  lang: string,
  correlationId: string
): Promise<ApiResponse<BookingApi.PreBookWithSwePayResponse | undefined>> {
  let price = amount;

  const body: BookingApi.InitBookingRequest = {
    productCode: "FTRF",
    passengers: passengers,
    trips: trips,
    correlationId: correlationId,
    clientName: "Globaltransfer",
    amount: amount,
    language: Convert(lang),
    currency: BookingApi.Currency.SEK
  };



  const response = await fetchWithCustomHeader(`${constants.Api.API_URL}/ftbook/initbooking`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(body),
  });

  if (!response.ok) {
    return new ApiResponse<BookingApi.PreBookWithSwePayResponse | undefined>(
      undefined,
      response.status,
      response.statusText
    );
  }

  let json = (await response.json()) as BookingApi.PreBookWithSwePayResponse;
  return new ApiResponse<BookingApi.PreBookWithSwePayResponse>(
    json,
    response.status
  );
}

export async function createBooking(
  swepayId: string,
  bookingRef: string,
  passengers: BookingApi.Passenger[],
  trips: BookingApi.Trip[],
  language: string,
  correlationId: string
): Promise<ApiResponse<string|undefined>> {
  const body: BookingApi.FinalizeBookingWithSwePayRequest = {
    swepayId: swepayId,
    bookingRef: bookingRef,
    productCode: "FTRF",
    passengers: passengers,
    trips: trips,
    language: Convert(language),
    correlationId: correlationId,
    clientName: "Aretransfer"
  };

  const response = await fetch(
    `${constants.Api.API_URL}/ftbook/book?officeid=ARETRANSFER`,
    {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(body),
    }
  );

  var json = await response.json();

  return new ApiResponse<string|undefined>(
    json.bookingReference,
    response.status,
    response.statusText
  );
}

export async function cancelPassenger(
  bookingRef: string,
  ftresid: string,
  lang: string
): Promise<ApiResponse<boolean>> {
  const body: BookingApi.CancelPassengerRequest = {
    bookingRef: bookingRef,
    ftresid: ftresid,
    language: Convert(lang)
  };

  const response = await fetchWithCustomHeader(
    `${constants.Api.API_URL}/ftbook/cancelPassenger`,
    {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(body),
    }
  );

  return new ApiResponse<boolean>(
    response.ok,
    response.status,
    response.statusText
  );
}

export async function cancelMyBooking(
  email: string,
  bookingRef: string,
  lang: string
): Promise<ApiResponse<boolean>> {
  const body: BookingApi.CancelBookingRequest = {
    bookingRef: bookingRef,
    email: email,
    language: Convert(lang)
  };

  const response = await fetchWithCustomHeader(
    `${constants.Api.API_URL}/ftbook/cancelBooking`,
    {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(body),
    }
  );

  return new ApiResponse<boolean>(
    response.ok,
    response.status,
    response.statusText
  );
}

export async function getValidateBooking(
  id: string
): Promise<BookingApi.BookingValidationResponse> {
  const response = await fetch(
    `${constants.Api.API_URL}/ftbook/validateBooking/${id}`
  );

  if (!response.ok) {
    return {bookingReference: "", status: 400};
  }
  
  return await response.json();
}

export async function abortPayment(id: string): Promise<ApiResponse<boolean>> {
  const requestOptions = {
    method: "PATCH",
    headers: {
      "Content-Type": "application/json",
    },
  };

  const response = await fetchWithCustomHeader(
    `${constants.Api.API_URL}/swepay/${id}/abort`,
    requestOptions
  );

  return new ApiResponse<boolean>(
    response.ok,
    response.status,
    response.statusText
  );
}

export async function getSiteCodes(): Promise<Array<string>> {
  try {
    const response = await fetchWithCustomHeader(
      `${constants.Api.API_URL}/transfer/sitecodes`
    );

    if (response.ok) {
      return await response.json();
    }

    if (!response.ok && response.status !== 404) {
      throw Error("Getting site codes failed!");
    }
   
  } catch (error: any) {
    //showBoundary(error);
  }
  return [];
}

export async function getTransferSites(): Promise<Array<BookingApi.TransferSite>> {
  try {
    const response = await fetchWithCustomHeader(
      `${constants.Api.API_URL}/transfer/transferSites`
    );

    if (response.ok) {

      const sites: Array<BookingApi.TransferSite> = await response.json();

      const transformedSites = sites.map((s: BookingApi.TransferSite) => ({
        ...s,
        open_from: new Date(s.open_from),
        open_to: new Date(s.open_to),
      }));

      return transformedSites;
    }

    if (!response.ok && response.status !== 404) {
      throw Error("Getting site codes failed!");
    }
   
  } catch (error: any) {
    //showBoundary(error);
  }
  return [];
}

export async function getCarouselData(): Promise<Array<BookingApi.CarouselImage>> {
  try {
    const response = await fetchWithCustomHeader(
      `${constants.Api.API_URL}/transfer/carousel`
    );

    if (response.ok) {
      return await response.json();
    }

    if (!response.ok && response.status !== 404) {
      throw Error("Getting site codes failed!");
    }
   
  } catch (error: any) {
    //showBoundary(error);
  }
  return [];
}

export async function getTransferLogo(): Promise<BookingApi.TransferLogoResponse> {
  try {
    const response = await fetchWithCustomHeader(
      `${constants.Api.API_URL}/transfer/transferlogo`
    );

    if (response.ok) {
      return await response.json();
    }    
   
  }
  catch (error: any) {
  }

  return { url: null };
}