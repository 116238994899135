export interface PickerDetails {
    number: string;
    time: string|undefined;
    date: string|undefined,
    direction: string,
    connectionCode: string,
    connectionType: string
}

export enum BOOKING_TYPE {
    FLIGHT = 0,
    TRAIN = 1,
    MYBOOKING = 2
}