import i18n from 'i18next'
import { Message, MessageType } from "./Type";
import constants from '../../constants/constants';
import { Convert } from '../../Utils/LangConverter';
import fetchWithCustomHeader from '../FetchUtil';

export async function getMessages(): Promise<Message[]> {
    const params = new URLSearchParams({ 'filter': 'are' });
    params.append("lang", Convert(i18n.language));

    const response = await fetchWithCustomHeader(`${constants.Api.API_URL}/messages?${params}`);
    if (response.ok) {
        return response.json();
    }
    
    return [];
}

export type { Message };
export { MessageType };
