import { useEffect, useState, forwardRef, useImperativeHandle } from "react";
import { useTranslation } from "react-i18next";
import {
  initializePayment,
  abortPayment,
} from "../../services/Booking/api";
import { useAppSelector, useAppDispatch } from "../../app/hooks";
import {
  BOOKING_STATUS,
  selectBooking,
  setPrebook,
  setStatus,
  currentPrice,
  BOOKING_TICKET_TYPE,
  setPayAbort,
} from "./bookingSlice";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { BookingApi } from "../../services/Booking/type";
import constants from "../../constants/constants";

//let payex: any;

interface ILang {
  lang: string;
}

export interface SwePaySeamlessPublicMethods {
  handleOnCancelClick: () => void;
}

const SwePaySeamless: React.ForwardRefRenderFunction<SwePaySeamlessPublicMethods, ILang> = ({ lang }, ref) => {
  const { t, i18n } = useTranslation();
  const bookingState = useAppSelector(selectBooking);
  const dispatch = useAppDispatch();
  const [abort, setAbort] = useState<boolean>(false);

  // Init pay sequence by setting state to init.
  useEffect(() => {
    const initPayment = async () => {
      const amount = currentPrice(
        bookingState.adultPrice,
        bookingState.childPrice,
        bookingState.passengers,
        bookingState.ticketType
      );

      const id = bookingState.id;
      const apiPassengers = bookingState.passengers.map((p) => {
        const passenger: BookingApi.Passenger = {
          firstName: p.firstName,
          lastName: p.lastName,
          email: p.email,
          phone: p.phone,
          isBillingPassenger: p.isBillingPassenger,
          childAge: !p.isAdult ? 11 : undefined,
          infoToDriver: p.infoToDriver ? undefined : t("large_luggage.infoToDriver", { ns: "translation" }).toString()
        };
        return passenger;
      });
  
      const trips: BookingApi.Trip[] = [];
  
      if (
        bookingState.ticketType === BOOKING_TICKET_TYPE.ONEWAY ||
        bookingState.ticketType === BOOKING_TICKET_TYPE.RETURN
      ) {
        trips.push({
          startCode: bookingState.outboundTrip.startNode.code,
          startTime: bookingState.outboundTrip.startNode.time,
          endCode: bookingState.outboundTrip.endNode.code,
          endTime: bookingState.outboundTrip.endNode.time,
          connection: {
            number: bookingState.outboundTrip.connection.number,
            depatureDate:
              bookingState.outboundTrip.connection.departureDate
                ?.toString() ?? "",
            depatureCode: bookingState.outboundTrip.connection.departureCode,
            arrivalDate:
              bookingState.outboundTrip.connection.arrivalDate
                ?.toString() ?? "",
            arrivalCode: bookingState.outboundTrip.connection.arrivalCode,
            type: bookingState.outboundTrip.connection.type,
          },
        });
      }
  
      if (bookingState.ticketType === BOOKING_TICKET_TYPE.RETURN) {
        trips.push({
          startCode: bookingState.inboundTrip.startNode.code,
          startTime: bookingState.inboundTrip.startNode.time,
          endCode: bookingState.inboundTrip.endNode.code,
          endTime: bookingState.inboundTrip.endNode.time,
          connection: {
            number: bookingState.inboundTrip.connection.number,
            depatureDate:
              bookingState.inboundTrip.connection.departureDate
                ?.toString() ?? "",
            depatureCode: bookingState.inboundTrip.connection.departureCode,
            arrivalDate:
              bookingState.inboundTrip.connection.arrivalDate
                ?.toString() ?? "",
            arrivalCode: bookingState.inboundTrip.connection.arrivalCode,
            type: bookingState.inboundTrip.connection.type,
          },
        });
      }

      const paymentResponse = await initializePayment(
        amount,
        apiPassengers,
        trips,     
        i18n.language,
        bookingState.correlationId
      );

      if (!paymentResponse.ok) {
        toast.error(paymentResponse.statusText);
        return;
      }
      dispatch(
        setPrebook({
          swepayId: paymentResponse.value?.swepayId ?? "error",
          href: paymentResponse.value?.seamlessHref ?? "error",
          bookingref: "...",
          corrId: paymentResponse.value?.correlationId ?? "",
        })
      );
    };
    if (bookingState.payHref === undefined) {
      initPayment();
    }
  }, [bookingState.payHref]);

  // Set pay will set state to PENDING-PAYMENT
  useEffect(() => {
    if (bookingState.payHref !== undefined) {
      const scriptElement = document.createElement("script");
      scriptElement.setAttribute("src", bookingState.payHref ?? "");
      scriptElement.onload = function () {
        //@ts-ignore
        payex.hostedView
          .checkout({
            container: {
              checkout: "checkout-container",
            },
            culture: `${lang}`,
            onPaid: async function onPay(payedEvent: any) {
              console.log(payedEvent);
              await finalizeBooking();
            },
          })
          .open();
      };
      document.body.appendChild(scriptElement);

      return () => {
           //@ts-ignore
           //payex.hostedView.checkout().cancel();
          //@ts-ignore
           payex.hostedView.checkout().close();
           document.body.removeChild(scriptElement);
      };
    }
  }, [bookingState.payHref]);

  useEffect(() => {
    if (abort) {
      //@ts-ignore
      payex.hostedView.checkout().close();
      //@ts-ignore
      payex.hostedView
        .checkout({
          container: {
            checkout: "checkout-container",
          },
          culture: `${lang}`,
          onAborted: function onAborted(abortEvent: any) {
            console.log(abortEvent);
            //@ts-ignore
            payex.hostedView.checkout().close();
            dispatch(setPayAbort());
          },
        })
        .open();
    }
  }, [abort]);

  const handleOnCancelClick = async () => {
    let response = await abortPayment(bookingState.payId);

    if (!response.ok) {
      toast.error(response.statusText);
      return;
    }
    setAbort(true);
  };

  useImperativeHandle(ref, () => ({
    handleOnCancelClick,
  }));

  const finalizeBooking = async () => {
    // Run the callback to finalize booking
    try{

      const body = JSON.stringify({
        client : "spa",
         orderReference : bookingState.correlationId,
         paymentOrder: {
           id: `/psp/paymentorders/${bookingState.payId}`,
           instrument: "paymentorders"
         }
       });
       console.log(body);

      const response = await fetch(`${constants.Api.API_URL}/swepay/callback`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: body
      });
    }finally{
      dispatch(setStatus(BOOKING_STATUS.DONE));
    }
  };

  return (
    <>
      <div className="p-5">
        <div id="checkout-container" />
        <div className="justify-center flex">
          <button
            onClick={handleOnCancelClick}
            className="w-auto p-5 mt-5 text-black font-bold bg-[#FFF3D5] hover:bg-[#ffdf88] rounded-md"
          >
            {t("cancelpay")}
          </button>
        </div>
      </div>
    </>
  );
}

export default forwardRef(SwePaySeamless);
