import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../../app/store";
import { DateType } from "react-tailwindcss-datepicker/dist/types";
import { BOOKING_TYPE } from "../../types/booking";
import { v4 as uuidv4 } from "uuid";


export enum BOOKING_TICKET_TYPE {
  ONEWAY = 1,
  RETURN = 2,
}

export enum BOOKING_STATUS {
  INIT = 0,
  AVAILABILITY = 1,
  PASSENGER = 2,
  PAY = 3,
  DONE = 4,
  ERROR = 5,
  PENDING = 6
}

export enum BOOKING_DIRECTION {
  FROM = "FROM_CONNECTION",
  TO = "TO_CONNECTION",
}

export interface BookingState {
  adults: number;
  children: number;
  outboundTrip: Trip;
  inboundTrip: Trip;
  id: string;
  payId: string;
  status: BOOKING_STATUS;
  ticketType: BOOKING_TICKET_TYPE;
  direction: BOOKING_DIRECTION;
  payHref: string | undefined;
  passengers: Passenger[];
  adultPrice: number;
  childPrice: number;
  bookingType: BOOKING_TYPE;
  correlationId: string;
  siteCode: string;
}

export interface Trip {
  startNode: TripNode;
  endNode: TripNode;
  connection: TripConnection;
}

export interface TripNode {
  time: string;
  code: string;
}

export interface TripConnection {
  number: string;
  departureDate: DateType | null;
  departureCode: string;
  arrivalDate: DateType | null;
  arrivalCode: string;
  carrierName: string;
  type: string
}

export interface Passenger {
  firstName: string;
  lastName: string;
  email: string;
  phone: string;
  isBillingPassenger: boolean;
  isAdult: boolean;
  price: number;
  id: string;
  infoToDriver: boolean
}

export interface PriceUpdate {
  adult: number;
  child: number;
}

export interface PrebookPayload {
  swepayId: string;
  href: string;
  bookingref: string;
  corrId: string
}

export interface NodeCodes {
  start: string;
  end: string;
}

const defaultAmount = {
  adult: 398,
  child: 199,
  vat: 0.06,
};

const defaultPassenger: Passenger = {
  firstName: "",
  lastName: "",
  email: "",
  phone: "",
  isAdult: true,
  isBillingPassenger: true,
  price: 0,
  id: generateUuid(),
  infoToDriver: false
};

const defaultConnection: TripConnection = {
  number: "",
  departureDate: null,
  departureCode: "",
  arrivalDate: null,
  arrivalCode: "",
  carrierName: "",
  type: "FLIGHT"
};

const defaultTrip: Trip = {
  startNode: {
    time: "",
    code: "",
  },
  endNode: {
    time: "",
    code: "",
  },
  connection: defaultConnection,
};

const initialState: BookingState = {
  id: "",
  adults: 1,
  children: 0,
  outboundTrip: defaultTrip,
  inboundTrip: defaultTrip,
  payId: "",
  status: BOOKING_STATUS.INIT,
  ticketType: BOOKING_TICKET_TYPE.RETURN,
  direction: BOOKING_DIRECTION.FROM,
  payHref: undefined,
  passengers: [defaultPassenger],
  adultPrice: 0,
  childPrice: 0,
  bookingType: BOOKING_TYPE.FLIGHT,
  correlationId: generateShortUuid(),
  siteCode: ""
};

export function currentPrice(
  adultPrice: number,
  childPrice: number,
  passengers: Passenger[],
  ticketType: BOOKING_TICKET_TYPE
): number {
  let adult = passengers
    .filter((r) => r.isAdult)
    .reduce((acc, item) => acc + adultPrice, 0);
  let child = passengers
    .filter((r) => !r.isAdult)
    .reduce((acc, item) => acc + childPrice, 0);

  switch (ticketType) {
    case BOOKING_TICKET_TYPE.ONEWAY:
      return adult + child;
    case BOOKING_TICKET_TYPE.RETURN:
      return (adult + child) * 2;
  }
}
function pop<T>(arr: Array<T>, predicate: (item: T) => boolean): T | undefined {
  const filteredArr = arr.filter(predicate);
  const poppedItem = filteredArr.pop();
  const poppedIndex = arr.indexOf(poppedItem!);
  arr.splice(poppedIndex, 1);
  return poppedItem;
}

export const bookingSlice = createSlice({
  name: "booking",
  initialState,
  reducers: {
    setSiteCode: (state, action: PayloadAction<string>) => {
      state.siteCode = action.payload
    },
    setAdults: (state, action: PayloadAction<number>) => {
      checkBookingRefData(state);

      if (state.adults < action.payload) {
        // add a passenger
        state.passengers.push({
          ...defaultPassenger,
          isAdult: true,
          isBillingPassenger: false,
          price: defaultAmount.adult,
        });
      } else {
        pop(state.passengers, (p) => p.isAdult);
      }
      state.adults = action.payload;
    },
    setChildren: (state, action: PayloadAction<number>) => {
      checkBookingRefData(state);
      if (state.children < action.payload) {
        // add a passenger
        state.passengers.push({
          ...defaultPassenger,
          isAdult: false,
          isBillingPassenger: false,
        });
      } else {
        pop(state.passengers, (p) => !p.isAdult);
      }

      state.children = action.payload;
    },

    verifyBillingPassenger: (state) => {
      // Sort isAdults first
      const isAdultPassengers = [];
      const nonAdultPassengers = [];

      for (let i = 0; i < state.passengers.length; i++) {
        if (state.passengers[i].isAdult) {
          isAdultPassengers.push(state.passengers[i]);
        } else {
          nonAdultPassengers.push(state.passengers[i]);
        }
      }
      state.passengers = [...isAdultPassengers, ...nonAdultPassengers];

      let hasBillingPassenger = false;
      let hasAdultPassenger = false;
      let billingPassengerIndex = -1;

      for (let i = 0; i < state.passengers.length; i++) {
        if (state.passengers[i].isBillingPassenger) {
          hasBillingPassenger = true;
          billingPassengerIndex = i;
        }
        if (state.passengers[i].isAdult) {
          hasAdultPassenger = true;
        }
      }
      if (!hasBillingPassenger) {
        if (hasAdultPassenger) {
          for (let i = 0; i < state.passengers.length; i++) {
            if (state.passengers[i].isAdult) {
              billingPassengerIndex = i;
              state.passengers[i].isBillingPassenger = true;
              break;
            }
          }
        } else {
          if (state.passengers.length > 0) {
            billingPassengerIndex = 0;
            state.passengers[0].isBillingPassenger = true;
          }
        }
      }

      // Reset isBillingPassenger to false for all passengers except the billing passenger
      for (let i = 0; i < state.passengers.length; i++) {
        if (i !== billingPassengerIndex) {
          state.passengers[i].isBillingPassenger = false;
        }
      }

      // Move the billing passenger to the beginning of the list
      if (billingPassengerIndex > 0) {
        const billingPassenger = state.passengers.splice(
          billingPassengerIndex,
          1
        )[0];
        state.passengers.unshift(billingPassenger);
      }
    },

    setOutboundNode: (state, action: PayloadAction<NodeCodes>) => {
      checkBookingRefData(state);
      state.outboundTrip.startNode.code = action.payload.start;
      state.outboundTrip.endNode.code = action.payload.end;
    },
    setInboundNode: (state, action: PayloadAction<NodeCodes>) => {
      checkBookingRefData(state);
      state.inboundTrip.startNode.code = action.payload.start;
      state.inboundTrip.endNode.code = action.payload.end;
    },
    setOutAvaTime: (state, action: PayloadAction<string[]>) => {
      state.outboundTrip.startNode.time = action.payload[0];
      state.outboundTrip.endNode.time = action.payload[1];
    },
    setInAvaTime: (state, action: PayloadAction<string[]>) => {
      state.inboundTrip.startNode.time = action.payload[0];
      state.inboundTrip.endNode.time = action.payload[1];
    },
    setOutboundConnection: (state, action: PayloadAction<TripConnection>) => {
      checkBookingRefData(state);
      state.outboundTrip.connection.arrivalCode = action.payload.arrivalCode;
      state.outboundTrip.connection.arrivalDate = action.payload.arrivalDate;
      state.outboundTrip.connection.carrierName = action.payload.carrierName;
      state.outboundTrip.connection.departureCode =
        action.payload.departureCode;
      state.outboundTrip.connection.departureDate =
        action.payload.departureDate;
      state.outboundTrip.connection.number = action.payload.number;
      state.outboundTrip.connection.type = action.payload.type

    },
    setInboundConnection: (state, action: PayloadAction<TripConnection>) => {
      checkBookingRefData(state);
      state.inboundTrip.connection.arrivalCode = action.payload.arrivalCode;
      state.inboundTrip.connection.arrivalDate = action.payload.arrivalDate;
      state.inboundTrip.connection.carrierName = action.payload.carrierName;
      state.inboundTrip.connection.departureCode = action.payload.departureCode;
      state.inboundTrip.connection.departureDate = action.payload.departureDate;
      state.inboundTrip.connection.number = action.payload.number;
      state.inboundTrip.connection.type = action.payload.type
    },
    setTicketType: (state, action: PayloadAction<BOOKING_TICKET_TYPE>) => {
      checkBookingRefData(state);
      state.ticketType = action.payload;
    },
    setPrice: (state, action: PayloadAction<PriceUpdate>) => {
      state.adultPrice = action.payload.adult;
      state.childPrice = action.payload.child;
    },
    setStatus: (state, action: PayloadAction<BOOKING_STATUS>) => {
      state.status = action.payload;
    },

    setPrebook: (state, action: PayloadAction<PrebookPayload>) => {
      state.payId = action.payload.swepayId;
      state.payHref = action.payload.href;
      state.id = action.payload.bookingref;
      state.correlationId = action.payload.corrId
    },
    setPayAbort: (state) => {
      state.payId = "";
      state.payHref = undefined;
      state.id = "";
      state.status = BOOKING_STATUS.PASSENGER;
    },
    setPassengers: (state, action: PayloadAction<Passenger[]>) => {
      state.passengers = action.payload.map((passenger) => ({ ...passenger }));
      state.status = BOOKING_STATUS.PASSENGER;
      checkBookingRefData(state);
    },
    reset: (state, action: PayloadAction<BOOKING_TYPE>) => {
      console.log("reset");
      return { ...initialState, id: "", correlationId : generateShortUuid(), bookingType: action.payload };
    },
    bookingDone: (state) => {
      console.log("booking done");
      return { ...initialState, id: "", correlationId : generateShortUuid(), status: BOOKING_STATUS.DONE };
    },
    setBookingRef: (state, action: PayloadAction<string>) => {
        state.id = action.payload;
    },
    swapDirection: (state) => {
      checkBookingRefData(state);

      const os = state.outboundTrip.startNode.code;
      const oe = state.outboundTrip.endNode.code;

      state.direction =
        state.direction === BOOKING_DIRECTION.FROM
          ? BOOKING_DIRECTION.TO
          : BOOKING_DIRECTION.FROM;

      state.outboundTrip.connection = defaultConnection;
      state.inboundTrip.connection = defaultConnection;

      state.outboundTrip.startNode.code = oe;
      state.outboundTrip.startNode.time = "";
      state.outboundTrip.endNode.code = os;
      state.outboundTrip.endNode.time = "";

      state.inboundTrip.startNode.code =
        state.ticketType === BOOKING_TICKET_TYPE.RETURN ? os : "";
      state.inboundTrip.startNode.time = "";
      state.inboundTrip.endNode.code =
        state.ticketType === BOOKING_TICKET_TYPE.RETURN ? oe : "";
      state.inboundTrip.endNode.time = "";
    },
    changeConnection: (state) => {
      state.outboundTrip.connection = defaultConnection;
      state.inboundTrip.connection = defaultConnection;
    },
    setBookingType: (state, action: PayloadAction<BOOKING_TYPE>) => {
      console.log({bookingtype: action.payload})
      state.bookingType = action.payload;
    },
  },
});

const checkBookingRefData = (state: BookingState) => {
  const { status } = state;

  if (state.id === "") return;

  if (
    status === BOOKING_STATUS.AVAILABILITY ||
    status === BOOKING_STATUS.INIT ||
    status === BOOKING_STATUS.PASSENGER
  ) {
    state.id = "";
    (state.payId = ""), (state.payHref = undefined);
  }

  return { ...state };
};


function generateUuid(): string {
 return  uuidv4();
}

function generateShortUuid(): string {
  const uid = uuidv4();
  const encoder = new TextEncoder();
  const data = encoder.encode(uid);

  // Convert the binary data to a Base64 string
  const base64Uid = btoa(String.fromCharCode(...data))
    .replace(/\+/g, "-")
    .replace(/\//g, "_")
    .replace(/=+$/, "");

  return base64Uid.substring(0, 30);
}



export const {
  setSiteCode,
  setAdults,
  setChildren,
  setOutboundConnection,
  setInboundConnection,
  setOutboundNode,
  setInboundNode,
  setTicketType,
  setStatus,
  setPrebook,
  setPassengers,
  setPrice,
  setOutAvaTime,
  setInAvaTime,
  reset,
  verifyBillingPassenger,
  setPayAbort,
  swapDirection,
  setBookingType,
  setBookingRef,
  bookingDone,
  changeConnection
} = bookingSlice.actions;

export const selectBooking = (state: RootState) => state.booking;

export default bookingSlice.reducer;
