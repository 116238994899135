import { useTranslation } from "react-i18next";
export default function Footer2() {
  const { t, i18n } = useTranslation();

  return (
    <footer className="bg-slate-50">
      <div className="container p-6 mx-auto">
        <div className="md:flex">
          <div className="w-full -mx-6 lg:w-3/4">
            <div className="px-6">

              <a href="#">
                <img className="w-auto h-7" src="/flygtaxi.png" alt="Flygtaxi" />
              </a>

              <p className="max-w-md mt-2 text-gray-500">{t("footer.about.long", { ns: "translation" })}</p>

            </div>
          </div>

          <div className="mt-6 lg:mt-0 lg:flex-1">
            <div className="grid grid-cols-1 gap-6">
              <div>
                <h3 className="text-gray-700 uppercase">{t("contactus", { ns: "common" })}</h3>
                <span className="block mt-2 text-sm text-gray-600">
                  Anderstorpsvägen 22<br />
                  Box 1054<br />
                  171 21 Solna</span>
                <span className="block mt-2 text-sm text-gray-600">+46 8120 920 00</span>
                <a href="mailto:support@flygtaxi.se" className="block mt-2 text-sm text-gray-600 hover:underline">support@flygtaxi.se</a>
              </div>
            </div>
          </div>
        </div>

        <hr className="h-px my-6 bg-gray-200 border-none" />

        <div>
          <p className="text-center text-sm text-gray-500">{t("footer.copyright", { ns: "translation"})}</p>
        </div>
      </div>
    </footer>
  );
}
