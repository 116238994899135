import i18n from "i18next";
import { initReactI18next } from "react-i18next";

import HttpBackend from "i18next-http-backend";
import LanguageDetector from "i18next-browser-languagedetector";

import constants from './constants/constants';


i18n
  .use(HttpBackend)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    fallbackLng: {
      'en-GB': ['en'],
      'se-SV': ['se'],
      'default': ['en']
    },
    ns: ['translation', 'common'],
    defaultNS: 'translation',
    debug: true,
    load: 'languageOnly',
    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },
    backend: {

      loadPath: (languages, namespaces) => {
        return `${constants.Api.API_URL}/locales/${languages}/${namespaces}`;
      },
      crossDomain: true,
      parse: (data) => JSON.parse(data),
      withCredentials: false,
      customHeaders: () => ({
        "Content-Type": "application/json",
        "X-Site-Code": sessionStorage.getItem('siteCode') ?? "notfound",
        "Accept-Language": i18n.language
      })
    }
  });

i18n.loadNamespaces(["translation", "common"]);
i18n.loadLanguages(["en", "sv"]);

export default i18n;
