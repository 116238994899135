import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "./store";
import { useSelector } from "react-redux";

export interface Location {
  name: string;
  serviceName: string;
  locationCode: string;
  productName: string;
  productCode: string;
  latitude: number;
  longitude: number;
  distance: string;
  duration: number;
  connection: boolean;
  externalLocationCode: string|undefined;
}

export interface LocationState {
  locationsA: Location[];
  locationsB: Location[];
}

const initialState: LocationState = {
  locationsA: [],
  locationsB: [],
};

export const locationsSlice = createSlice({
  name: "locations",
  initialState,
  reducers: {
    setConnectionLocations: (state, action: PayloadAction<Location[]>) => {
      state.locationsA = action.payload;
    },
    setLocations: (state, action: PayloadAction<Location[]>) => {
      state.locationsB = action.payload;
    },
    swapLocations: (state) => {
      const a = state.locationsA.slice();
      const b = state.locationsB.slice();

      state.locationsA = b;
      state.locationsB = a;
    },
  },
});

export const { setConnectionLocations, setLocations, swapLocations } =
  locationsSlice.actions;

export const selectLocations = (state: RootState) => state.locations;

export default locationsSlice.reducer;
