import { useTranslation } from "react-i18next";
import SwePaySeamless, { SwePaySeamlessPublicMethods } from "./SwepaySeamless";
import { useAppSelector, useAppDispatch } from "../../app/hooks";
import { selectLocations } from "../../app/locationSlice";
import BookingStepsHeader from "./BookingStepsHeader";
import { BOOKING_STATUS, Passenger, selectBooking } from "./bookingSlice";
import { Convert } from "../../Utils/LangConverter";
import { MyBooking as PassengerTripBooking } from "./PassengerTripInfo";
import { MyBooking as TripTotalBooking } from "./TripTotal";
import React, { useRef } from 'react'
import TimeoutCountdown from './TimeoutCountdown';

export default function BookingPay() {
  const bookingState = useAppSelector(selectBooking);
  const locationsState = useAppSelector(selectLocations);
  const dispatch = useAppDispatch();
  const { t, i18n } = useTranslation();

  const swePayRef = useRef<SwePaySeamlessPublicMethods | null>(null);

  const distinctPassengers = Array.from(
    bookingState.passengers
      .map((s: Passenger) => ({
        firstName: s.firstName,
        lastName: s.lastName,
        email: s.email,
        phone: s.phone,
        isAdult: s.isAdult,
        price: s.isAdult ? bookingState.adultPrice : bookingState.childPrice,
        cancelled: false,
        id: s.id,
        infotoDriver: s.infoToDriver
      }))
      .reduce((map, obj) => {
        const key = `${obj.id}`;
        if (!map.has(key)) map.set(key, obj);
        return map;
      }, new Map())
      .values()
  );

  const trips: PassengerTripBooking.Trip[] = [];
  let totalPrice = 0;

  if (bookingState.outboundTrip.startNode.code !== "") {
    trips.push({
      passengers: distinctPassengers,
      type: bookingState.outboundTrip.connection.type,
      number: bookingState.outboundTrip.connection.number,
      dropoffTime:
        bookingState.outboundTrip.endNode.time?.toString() ?? "",
      dropoffLocationCode: bookingState.outboundTrip.connection.departureCode,
      pickupTime:
        bookingState.outboundTrip.startNode.time?.toString() ?? "",
      pickupLocationCode: bookingState.outboundTrip.connection.arrivalCode,
      startCode: bookingState.outboundTrip.startNode.code,
      startName:
        locationsState.locationsB
          .concat(locationsState.locationsA)
          .find(
            (x) => x.locationCode == bookingState.outboundTrip.startNode.code
          )?.name ?? "",
      endCode: bookingState.outboundTrip.endNode.code,
      endName:
        locationsState.locationsB
          .concat(locationsState.locationsA)
          .find((x) => x.locationCode == bookingState.outboundTrip.endNode.code)
          ?.name ?? "",
    });

    totalPrice +=
      bookingState.passengers.filter((p) => p.isAdult).length *
      bookingState.adultPrice;

    totalPrice +=
      bookingState.passengers.filter((p) => !p.isAdult).length *
      bookingState.childPrice;
  }

  if (bookingState.inboundTrip.startNode.time !== "") {
    trips.push({
      passengers: distinctPassengers,
      type: bookingState.inboundTrip.connection.type,
      number: bookingState.inboundTrip.connection.number,
      dropoffTime:
        bookingState.inboundTrip.endNode.time?.toString() ?? "",
      dropoffLocationCode: bookingState.inboundTrip.connection.departureCode,
      pickupTime:
        bookingState.inboundTrip.startNode.time?.toString() ?? "",
      pickupLocationCode: bookingState.inboundTrip.connection.arrivalCode,
      startCode: bookingState.inboundTrip.startNode.code,
      startName:
        locationsState.locationsB
          .concat(locationsState.locationsA)
          .find((x) => x.locationCode == bookingState.outboundTrip.endNode.code)
          ?.name ?? "",
      endCode: bookingState.inboundTrip.endNode.code,
      endName:
        locationsState.locationsB
          .concat(locationsState.locationsA)
          .find(
            (x) => x.locationCode == bookingState.outboundTrip.startNode.code
          )?.name ?? "",
    });

    totalPrice +=
      bookingState.passengers.filter((p) => p.isAdult).length *
      bookingState.adultPrice;

    totalPrice +=
      bookingState.passengers.filter((p) => !p.isAdult).length *
      bookingState.childPrice;
  }

  const cancelHandler = () => {
    if (swePayRef.current) {
      swePayRef.current.handleOnCancelClick();
    }
  };

  return (
    <div className="relative w-full py-20 h-auto bg-white">
      <div className="max-w-[1280px] mx-auto px-[8.66%]">
        <BookingStepsHeader status={BOOKING_STATUS.PAY} />

        <div className="flex items-center justify-between space-x-2 mt-12">
          <div />

          <h1 className="text-2xl font-semibold text-center text-gray-800 capitalize lg:text-3xl">
            {t("payment", { ns: "common" })}
          </h1>

          <TimeoutCountdown time={600000} interval={500} cancelHandler={cancelHandler} />
        </div>

        <div className="my-6 space-y-8 xl:mt-12">
          {trips.map((item, i) => (
            <div key={`${item?.pickupLocationCode}${i}`}>
              <div className="">
                <PassengerTripBooking.PassengerTripInfo
                  trip={item}
                  lang={Convert(i18n.language)}
                  readOnly={true}
                  onCancel={() => {}}
                />
              </div>
            </div>
          ))}

          <TripTotalBooking.TripTotal
            currency="SEK"
            lang={Convert(i18n.language)}
            totalPrice={totalPrice}
            vat={0.06}
          />

          <SwePaySeamless lang={Convert(i18n.language)} ref={swePayRef} />
        </div>

        {/* <div className="w-full flex flex-col-reverse items-center gap-y-6 mt-6 lg:flex-row lg:justify-between">
          <button className={`w-full py-4 px-12 text-white font-bold bg-gray-500 border-1 uppercase lg:w-auto`} >
            {t("back", { ns: "common" })}
          </button>
        </div> */}
      </div>
    </div>
  );
}
