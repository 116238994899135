import { useEffect, useState, useCallback } from 'react'
import { getMessages, Message, MessageType } from "../services/Messages/Api";
import i18n from 'i18next'

interface MessageProps {
    siteCode: string
}


function Messages({ siteCode }: MessageProps) {

    const [messages, setMessages] = useState<Message[]>([]);
    const [dismissedMessages, setDismissedMessages] = useState<string[]>([]);

    useEffect(() => {

        if(!siteCode) return;

        getData();

        i18n.on('languageChanged', getData);

        return () => {
            i18n.off('languageChanged', getData);
        };
    }, [siteCode]);

    useEffect(() => {
        if (!siteCode) {
            setMessages([]);
            return;
        }

        const filteredMessages = messages.filter((msg) => {
            return !dismissedMessages.includes(msg.id);
        });
        
        setMessages(filteredMessages);
    }, [dismissedMessages]);

    const getData = async () => {

        try {
            const msgs = await getMessages();
            const dismissedMessages = sessionStorage.getItem('dismissed_messages');
            setMessages(msgs ?? []);
            setDismissedMessages(dismissedMessages ? JSON.parse(dismissedMessages) : []);
        }
        catch (error) {
            setMessages([]);
        }
    };

    const handleDismiss = (message: Message) => {

        const updatedDismissedMessages = [...dismissedMessages, message.id];
        sessionStorage.setItem('dismissed_messages', JSON.stringify(updatedDismissedMessages));

        setMessages((prevMessages) => prevMessages.filter((msg) => msg !== message));
        setDismissedMessages(updatedDismissedMessages);
    };

    return (
        <>
            {messages &&
                <div id="messages" className={`sticky z-50 top-0 w-full `}>
                    {
                        messages.map((msg, i) => (
                            <div key={'msg-' + i} className={`px-6 py-4 mx-auto flex items-center text-white ${msgToStyle(msg).bg}`}>
                                <div className='flex-1 flex'>
                                    {msgToStyle(msg).icon()}
                                    <p className="mx-3" dangerouslySetInnerHTML={{ __html: msg.message }}></p>
                                </div>

                                {/*
                            {msg.showReload &&
                                <button className="p-1 transition-colors duration-300 transform rounded-md hover:bg-opacity-25 hover:bg-gray-600 focus:outline-none">
                                    <svg className="w-5 h-5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
                                        <path fillRule="evenodd" d="M4 2a1 1 0 011 1v2.101a7.002 7.002 0 0111.601 2.566 1 1 0 11-1.885.666A5.002 5.002 0 005.999 7H9a1 1 0 010 2H4a1 1 0 01-1-1V3a1 1 0 011-1zm.008 9.057a1 1 0 011.276.61A5.002 5.002 0 0014.001 13H11a1 1 0 110-2h5a1 1 0 011 1v5a1 1 0 11-2 0v-2.101a7.002 7.002 0 01-11.601-2.566 1 1 0 01.61-1.276z" clipRule="evenodd" />
                                    </svg>
                                </button>
                            }
                            */}

                                { msg.showClose &&
                                    <button className="p-1 transition-colors duration-300 transform rounded-md hover:bg-opacity-25 hover:bg-gray-600 focus:outline-none" onClick={() => handleDismiss(msg)}>
                                        <svg className="w-5 h-5" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M6 18L18 6M6 6L18 18" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                        </svg>
                                    </button>
                                }
                            </div>
                        ))
                    }
                </div>
            }
        </>
    )
}

interface MessageTypeStyle {
    type: MessageType;
    bg: string;
    icon: () => JSX.Element;
}

const icon = {
    info: () => {
        return (
            <svg viewBox="0 0 40 40" className="w-6 h-6 fill-current">
                <path d="M20 3.33331C10.8 3.33331 3.33337 10.8 3.33337 20C3.33337 29.2 10.8 36.6666 20 36.6666C29.2 36.6666 36.6667 29.2 36.6667 20C36.6667 10.8 29.2 3.33331 20 3.33331ZM21.6667 28.3333H18.3334V25H21.6667V28.3333ZM21.6667 21.6666H18.3334V11.6666H21.6667V21.6666Z">
                </path>
            </svg>
        );
    },
    error: () => {
        return (
            <svg className="w-6 h-6 text-white fill-current" viewBox="0 0 40 40" xmlns="http://www.w3.org/2000/svg">
                <path d="M20 3.36667C10.8167 3.36667 3.3667 10.8167 3.3667 20C3.3667 29.1833 10.8167 36.6333 20 36.6333C29.1834 36.6333 36.6334 29.1833 36.6334 20C36.6334 10.8167 29.1834 3.36667 20 3.36667ZM19.1334 33.3333V22.9H13.3334L21.6667 6.66667V17.1H27.25L19.1334 33.3333Z" />
            </svg>
        );
    },
    unknown: () => {
        return (
            <></>
        );
    },
}

const styles: ReadonlyArray<MessageTypeStyle> = [
    { type: MessageType.Info, bg: 'bg-blue-500', icon: icon.info },
    { type: MessageType.Error, bg: 'bg-red-500', icon: icon.error },
];

const msgToStyle = (msg: Message): MessageTypeStyle => {
    return styles.find(s => s.type == msg.type) ?? { type: MessageType.Info, bg: 'bg-blue-500', icon: icon.unknown };
}

export default Messages;