import { useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../app/hooks";
import { selectBooking, setSiteCode } from "../components/Booking/bookingSlice";
import { useTranslation } from "react-i18next";
import { useEffect, useState } from "react";
import { getTransferSites } from "../services/Booking/api";
import { BookingApi } from "../services/Booking/type";
import Footer from "../components/Footer";
import LanguagePicker from '../components/LanguagePicker';

interface SiteVM {
  Name: string;
  Img: string;
  Code: string;
  Open: boolean;
}


export default function Transfers() {

  const navigate = useNavigate();
  const bookingState = useAppSelector(selectBooking);
  const dispatch = useAppDispatch();
  const { t, i18n } = useTranslation();

  const [activeSiteCodes, setActiveSiteCodes] = useState<string[]>([]);
  const [transferSites, setTransferSites] = useState<SiteVM[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(true);



  useEffect(() => {
    if (bookingState.siteCode !== "") {
      dispatch(setSiteCode(""))
    }

    const fetchSites = async () => {
      try {
        const now = new Date;
        const sites = await getTransferSites()

        setTransferSites(
          sites.map((s: BookingApi.TransferSite) => ({
            Name: s.name,
            Img: s.img,
            Code: s.code,
            Open: s.open_to > now
          })));

        //setActiveSiteCodes();
      } catch (error) {
        console.log(error);
      }

       setIsLoading(false);
    };
    fetchSites();
  }, []);

  return (
    <div className="flex flex-col min-h-screen bg-white">
      
      {isLoading &&
        <div className="absolute inset-0 flex justify-center items-center">
          <img src={"ft_spinner.svg"} alt="Loading" />
        </div>
      }

      {!isLoading &&

        <>

          <nav className="w-full max-w-[2560px] p-5 z-20 flex bg-white/30 border-b-gray-300/30 border">
            <div className="bg-center bg-no-repeat bg-contain w-48" style={{ backgroundImage: "url('/flygtaxi.png')" }} />
            <div className="flex-1 flex items-center justify-end"><LanguagePicker /></div>
          </nav>

          <div className="flex-1 container px-6 py-10 mx-auto">

            <h1 className="text-2xl font-semibold text-center text-gray-800 lg:text-3xl">
              {transferSites.length > 0
                ? t("selectTransfer", { ns: "translation" })
                : t("noTransfersAvailable", { ns: "translation" })}
            </h1>

            <div className="grid grid-cols-1 gap-8 mt-8 xl:mt-12 xl:gap-12 lg:grid-cols-3">
              {transferSites
                .sort((a: SiteVM, b: SiteVM) => {
                  if (a.Open === b.Open) {
                    return a.Name.localeCompare(b.Name);
                  }
                  return b.Open ? 1 : -1;
                }).map((site) =>
                (
                  <a
                    key={site.Code}
                    href={site.Open ? `/${site.Code}` : undefined}
                    className={`flex items-end overflow-hidden bg-cover rounded-lg h-72 ${site.Open ? '' : 'opacity-50 cursor-not-allowed'}`}
                    style={{ backgroundImage: `url(${site.Img})` }}
                  >
                    <div className="w-full flex justify-between items-center px-8 py-4 overflow-hidden rounded-b-lg backdrop-blur-sm bg-white/60">
                      <h2 className="text-xl font-semibold text-gray-800 capitalize">{site.Name}</h2>
                      <p className="text-lg tracking-wider text-ft-blue-dark uppercase">{t(site.Open ? "open" : "closed", { ns: "common" })}</p>
                    </div>
                  </a>))
              }
            </div>

          </div>

          <Footer />

        </>
      }
    </div>
  );
}