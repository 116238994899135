import StartBooking from "./BookingStart";
import BookingPassengers from "./BookingPassengers";
import BookingPay from "./BookingPay";
import BookingDone from "./BookingDone";
import Map from "../Map/Map";

import { useEffect } from 'react';
import { useSearchParams } from "react-router-dom";
import { useAppSelector } from "../../app/hooks";
import {
  BOOKING_STATUS,
  selectBooking,
} from "../../components/Booking/bookingSlice";
import BookingAvailability from "./BookingAvailability";
import BookingError from "./BookingError";

import { useLocation } from 'react-router-dom';
import BookingPending from "./BookingPending";

export default function Booking() {
  const bookingState = useAppSelector(selectBooking);
  let [searchParams, setSearchParams] = useSearchParams();

  
  const location = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location, bookingState.status]);

  useEffect(() => {
  }, [searchParams]);


  return (
    (() => {
      switch (bookingState.status) {
        case BOOKING_STATUS.INIT:
          return <StartBooking />;
        case BOOKING_STATUS.AVAILABILITY:
          return <BookingAvailability />;
        case BOOKING_STATUS.PASSENGER:
          return <BookingPassengers />;
        case BOOKING_STATUS.PAY:
          return <BookingPay />;
        case BOOKING_STATUS.DONE:
          return <BookingDone />;
        case BOOKING_STATUS.ERROR:
          return <div>
            <BookingError />
          </div>;
        case BOOKING_STATUS.PENDING:
          return <div>
            <BookingPending />
          </div>;
      }
    })()
  );

}
