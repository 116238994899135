import { useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import {
  reset,
  BOOKING_STATUS,
  selectBooking,
  bookingDone,
  setStatus
} from "./bookingSlice";
import { useTranslation } from 'react-i18next';
import { BOOKING_TYPE } from '../../types/booking';
import { getValidateBooking } from '../../services/Booking/api';
import { useNavigate } from 'react-router-dom';
export default function BookingDone() {
  const dispatch = useAppDispatch();
  const bookingState = useAppSelector(selectBooking);
  const { t, i18n } = useTranslation();
  const [bookingId, setBookingId] = useState<string>();
  const [email, setEmail] = useState<string>();
  const [validatingBooking, setValidatingBooking] = useState<boolean>(true);
  const navigate = useNavigate();

  sessionStorage.clear();

  const validate = async () => {

    try {
      console.log(bookingState);

      const validation = await getValidateBooking(bookingState.correlationId);

      if (validation.status !== 200) {
        dispatch(setStatus(BOOKING_STATUS.PENDING));
        return;
      }

      setBookingId(validation.bookingReference);
      setEmail(bookingState?.passengers[0]?.email);
      dispatch(bookingDone());
      setValidatingBooking(false);
    }
    catch (error) {
      dispatch(setStatus(BOOKING_STATUS.ERROR));
    }
  };

  useEffect(() => {
    validate();
  }, []);

  return (
    <>
      <div className="relative w-full py-20 h-auto bg-white">
        <div className="max-w-[1280px] mx-auto px-[8.66%]">

          {validatingBooking ?
            <div className="cubespinner">
              <div className="face face1">{t("confirmationSpinner.creatingOrder", { ns: "translation" })}</div>
              <div className="face face2">{t("confirmationSpinner.verifyingPayment", { ns: "translation" })}</div>
              <div className="face face3">{t("confirmationSpinner.confirmingBooking", { ns: "translation" })}</div>
              <div className="face face4">{t("confirmationSpinner.finalizingProcess", { ns: "translation" })}</div>
            </div>
            :
            <>
              <h1 className="mt-12 text-2xl font-semibold text-center text-gray-800 lg:text-3xl">{t('bookingCompleted', { ns: 'common' })}</h1>
              <h2 className="text-xl text-center mt-12 mb-6 font-medium text-gray-600">{t('bookingnumber', { ns: 'common' })}: {bookingId}</h2>

              <div className="w-full flex flex-col-reverse items-center gap-y-6 mt-6 lg:flex-row lg:justify-between">
              <button
                type="button"
                onClick={() => { navigate(`/${bookingState.siteCode}/reference?id=${bookingId}&email=${email}`) }}
                className={`w-full py-4 px-12 text-white font-bold bg-gray-500 border-1 uppercase lg:w-auto`}
              >
                {t("mybooking", { ns: "translation" })}
              </button>
              <button onClick={() => { dispatch(setStatus(BOOKING_STATUS.INIT)); }} className={`w-full py-4 px-12 text-white font-bold bg-ft-blue-dark border-1 uppercase lg:w-auto`}>
                  {t("close", { ns: "common" })}
                </button>
            </div>
            </>
          }

        </div>
      </div>
    </>
  );
}
