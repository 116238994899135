
import Footer from "../components/Footer";
import Booking from "../components/Booking/Booking";
import Navbar from "../components/Navbar";
import { useEffect } from "react";
import { useNavigate } from 'react-router-dom';
import { useAppSelector } from "../app/hooks";
import { BOOKING_STATUS, selectBooking, } from "../components/Booking/bookingSlice";

function MyBooking() {

  const bookingState = useAppSelector(selectBooking);
  const navigate = useNavigate();

  useEffect(() => {
    if (bookingState.status === BOOKING_STATUS.INIT)
      navigate('/' + bookingState.siteCode);
  }, [bookingState]);

  return (
    <div className="min-h-screen flex flex-col">
      <Navbar />
      <div className="flex-1 bg-white">
        <Booking />
      </div>
      <Footer />
    </div>
  );
}

export default MyBooking;
