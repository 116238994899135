import Skeleton from 'react-loading-skeleton';
import { t } from 'i18next'
import { BOOKING_STATUS, selectBooking, setStatus } from './bookingSlice';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import { useNavigate } from 'react-router-dom';

interface BookingStepsProperties {
    status: BOOKING_STATUS;
}

function BookingStepsHeader({ status: status }: BookingStepsProperties) {
    
    const dispatch = useAppDispatch();
    const bookingState = useAppSelector(selectBooking);
    const navigate = useNavigate();

    const pastLi = " text-blue-600 hover:cursor-pointer"
    const li = "flex px-0 md:w-full items-center md:after:content-[''] after:w-full after:h-1 after:border-b after:border-gray-200 after:border-1 after:hidden md:after:inline-block after:mx-6 xl:after:mx-10";
    const lastLi = "flex items-center px-0";

    const Span = "flex items-center after:content-['/'] md:after:hidden after:mx-2 after:text-gray-200";
    const lastSpan = "mr-2";

    const setState = async (state:BOOKING_STATUS) => {
        if(status > state){

            if(bookingState.id !== '' && state === BOOKING_STATUS.AVAILABILITY){
            }

            dispatch(setStatus(state));
            if(state == BOOKING_STATUS.INIT)
                navigate('/'+bookingState.siteCode);
        }
      };

    return (
        <ol className="w-full max-w-[960px] px-[10%] mx-auto flex items-center justify-center text-sm font-medium text-center text-gray-500 md:text-base">

            {states.map((state, i) => (
                <li key={i} className={(i !== states.length - 1 ? li : lastLi) + (state.state <= status ? pastLi : "")}>
                    <span className={i !== states.length - 1 ? Span : lastSpan} onClick={() => { setState(state.state); }}>
                        {state.state < status &&
                            <svg aria-hidden="true" className="w-4 h-4 mr-2 sm:w-5 sm:h-5" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z" clipRule="evenodd"></path></svg>
                        }
                        
                        {t(state.textKey, { ns: 'common' })}
                    </span>
                </li>
            ))}
        </ol>
    )
}

interface State {
    state: BOOKING_STATUS;
    textKey: string;
}

const states: ReadonlyArray<State> = [
    { state: BOOKING_STATUS.INIT, textKey: 'search' },
    { state: BOOKING_STATUS.AVAILABILITY, textKey: 'tickets' },
    { state: BOOKING_STATUS.PASSENGER, textKey: 'passenger' },
    { state: BOOKING_STATUS.PAY, textKey: 'payment' },
];

export default BookingStepsHeader;