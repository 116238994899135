import React, { useEffect, useState, useCallback, useRef } from 'react'
import { BookingApi } from "../services/Booking/type";

interface CarouselProperties {
    images: BookingApi.CarouselImage[];
}

function Carousel(properties: CarouselProperties) {

    const [currentImage, setCurrentImage] = useState(0);
    const refs = useRef<Array<React.RefObject<HTMLDivElement>>>(properties.images.map(() => React.createRef()));
    const [timer, setTimer] = useState<NodeJS.Timer>();

    useEffect(() => {

        if (!timer) {
            setTimer(setInterval(scrollNext, 8000));
        }

        return () => {
            clearTimer();
        };

    }, [currentImage]);

    const clearTimer = () => {
        clearInterval(timer);
        setTimer(undefined);
    };

    const scrollNext = (forward: boolean = true) => {
        setCurrentImage((c) => {
            const i = forward ? ((c >= properties.images.length - 1) ? 0 : c + 1) : ((c === 0) ? (properties.images.length - 1) : c - 1);
            const container = refs.current[i]?.current?.parentNode as HTMLElement;
            if (container) {
                const imagePosition = refs.current[i]?.current?.offsetLeft || 0;
                container.scrollTo({
                    left: imagePosition,
                    behavior: 'smooth',
                });
            }
            return i;
        });

    }

    const nextImage = () => {
        clearTimer();
        scrollNext();
    };

    const previousImage = () => {
        clearTimer();
        scrollNext(false);
    };

    return (
        <div id="carousel">

            {/* Carousel controls - prev item */}
            <button onClick={previousImage} className="absolute bottom-0 left-0 top-0 z-[1] flex w-[15%] items-center justify-center border-0 bg-none p-0 text-center text-white opacity-50 transition-opacity duration-150 ease-[cubic-bezier(0.25,0.1,0.25,1.0)] hover:text-white hover:no-underline hover:opacity-90 hover:outline-none focus:text-white focus:no-underline focus:opacity-90 focus:outline-none motion-reduce:transition-none" type="button">
                <span className="inline-block h-8 w-8">
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="h-6 w-6">
                        <path strokeLinecap="round" strokeLinejoin="round" d="M15.75 19.5L8.25 12l7.5-7.5" />
                    </svg>
                </span>
            </button>

            <div className="absolute inset-0 overflow-hidden flex">

                {/* Carousel items */}                {
                    properties.images.map((image, i) =>
                    (
                        <div className="relative w-full h-full flex-shrink-0" key={image.url} ref={refs.current[i]}>
                            <img src={image.url} alt={image.alt ?? undefined} className="absolute inset-0 w-full h-full object-cover" />
                            {image.text &&
                                <h1 className="hidden absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 max-w-[80%] text-5xl font-bold text-white bg-black/20 text-center p-5 xl:block">{image.text}</h1>
                            }
                            <div className="absolute bottom-0 w-full h-24 bg-gradient-to-b from-white/0 to-white lg:hidden" />
                        </div>
                    )
                    )
                }

            </div>

            {/* Carousel controls - next item */}
            <button onClick={nextImage} className="absolute bottom-0 right-0 top-0 z-[1] flex w-[15%] items-center justify-center border-0 bg-none p-0 text-center text-white opacity-50 transition-opacity duration-150 ease-[cubic-bezier(0.25,0.1,0.25,1.0)] hover:text-white hover:no-underline hover:opacity-90 hover:outline-none focus:text-white focus:no-underline focus:opacity-90 focus:outline-none motion-reduce:transition-none" type="button">
                <span className="inline-block h-8 w-8">
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="h-6 w-6">
                        <path strokeLinecap="round" strokeLinejoin="round" d="M8.25 4.5l7.5 7.5-7.5 7.5" />
                    </svg>
                </span>
            </button>

        </div>
    );
}

export default Carousel;