
export interface ApiResponseEx<T> {
  value: T,
  status: number,
  statusText: string | undefined
}



export class ApiResponse<T> {
  value: T | undefined;
  status: number;
  statusText: string | undefined;

  constructor(value: T, status: number, statusText : string | undefined = undefined) {
    this.value = value;
    this.status = status;
    this.statusText = statusText
  }

  get ok(): boolean {
    return this.status >= 200 && this.status < 300;
  }
}


