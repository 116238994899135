import { useRef, useLayoutEffect, useState } from "react";
import H from "@here/maps-api-for-javascript";
import "@here/maps-api-for-javascript/bin/mapsjs-ui.css";
import ReactDOMServer from "react-dom/server";
import { useAppSelector, useAppDispatch } from "../../app/hooks";
import { Location, selectLocations } from "../../app/locationSlice";
import {
  BOOKING_TICKET_TYPE,
  selectBooking,
  setInboundNode,
  setOutboundNode,
} from "../Booking/bookingSlice";
import constants from "../../constants/constants";

function Map() {
  const ref = useRef<HTMLDivElement>(null);
  const [map, setMap] = useState<H.Map | null>(null);
  const [router, setRouter] = useState<H.service.RoutingService | null>(null);

  const locationsState = useAppSelector(selectLocations);
  const bookingState = useAppSelector(selectBooking);
  const dispatch = useAppDispatch();

  useLayoutEffect(() => {
    try {
      if (!map) init();

      redrawMarkers();

      // Add event listener for window resize event
      window.addEventListener("resize", handleResize);
    } catch (error) {
      console.log("Could not update map. Error: " + error);
    }

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [map, locationsState]);

  useLayoutEffect(() => {
    try {
      redrawMarkers();
    } catch (error) {
      console.log("Could not update map. Error: " + error);
    }
  }, [bookingState]);

  const init = (): void => {
    if (ref.current) {
      const platform = new H.service.Platform({
        apikey: "qdHJx7izah0U1NALUYYjIMJzsNO2gTFkHWWd_Eu0Yrk",
      });

      const defaultLayers: any = platform.createDefaultLayers();
      const map = new H.Map(ref.current, defaultLayers.vector.normal.map, {
        pixelRatio: window.devicePixelRatio || 1,
        zoom: 2,
        padding: { bottom: 70, left: 70, right: 120, top: 70 },
      });

      const ui = H.ui.UI.createDefault(map, defaultLayers, "en-US");
      const mapSettingsControl: any = ui.getControl("mapsettings");
      mapSettingsControl.setVisibility(true);

            const scrollFixer = document.getElementById('scroll-fixer');
            if (scrollFixer != null) {
                ref.current.addEventListener('wheel', () => {
                    scrollFixer.style.display = 'block';
                setTimeout(() => {
                    scrollFixer.style.display = 'none';
                }, 250);
            });
        }

      const behavior = new H.mapevents.Behavior(new H.mapevents.MapEvents(map));
      behavior.disable(H.mapevents.Behavior.Feature.WHEEL_ZOOM);

      setRouter(platform.getRoutingService(undefined, 8));
      setMap(map);
    }
  };

  const redrawMarkers = (): void => {
    if (!map) return;

    map.removeObjects(map.getObjects());
    const markers: H.map.Object[] = [];

    locationsState.locationsB
      .concat(locationsState.locationsA)
      .filter((l) => !l.connection)
      .forEach((location) => {
        const selected =
          bookingState.outboundTrip.startNode.code === location.locationCode ||
          bookingState.outboundTrip.endNode.code === location.locationCode ||
          !bookingState.outboundTrip.startNode.code ||
          !bookingState.outboundTrip.endNode.code;

        const marker = new H.map.Marker(
          {
            lat: location.latitude,
            lng: location.longitude,
          },
          {
            zIndex: selected ? 11 : 10,
            icon: Icon(selected),
            data: location.locationCode,
          }
        );
        //marker.setData({maxZoom: 10});

        createInfoBubble(map, marker, location);
        createInfoBubble(map, marker, location);

        markers.push(marker);
      });

    if (markers === null || markers.length === 0) return;

    // We add our newly created makers and objects to the object container
    const container = new H.map.Group({
      objects: markers,
      data: "",
    });

    container.addEventListener("tap", function (evt: any) {
      setLocation(evt.target.getData());
    });

    const showRoute =
      bookingState.outboundTrip.startNode.code !== "" &&
      bookingState.outboundTrip.endNode.code !== "";

    if (showRoute) {
      
      const start = locationsState.locationsB
        .concat(locationsState.locationsA)
        .find(
          (location) =>
            location.locationCode === bookingState.outboundTrip.startNode.code
        );
      const end = locationsState.locationsB
        .concat(locationsState.locationsA)
        .find(
          (location) =>
            location.locationCode === bookingState.outboundTrip.endNode.code
        );

      if (
        start !== null &&
        start !== undefined &&
        end !== null &&
        end !== undefined
      ) {
        const routeRequestParams = {
          routingMode: "fast",
          transportMode: "car",
          origin: `${start.latitude},${start.longitude}`,
          destination: `${end.latitude},${end.longitude}`,
          return: "polyline",
        };

        router?.calculateRoute(
          routeRequestParams,
          (result: any) => {
            var route = result.routes[0];
            route.sections?.forEach((section: any) => {
              // decode LineString from the flexible polyline
              let linestring = H.geo.LineString.fromFlexiblePolyline(
                section.polyline
              );

              // Create a polyline to display the route:
              let polyline = new H.map.Polyline(linestring, {
                style: {
                  lineWidth: 4,
                  strokeColor: "rgba(0, 128, 255, 0.7)",
                },
                data: [],
              });

              // Add the polyline to the map
              container.addObject(polyline);

              // And zoom to its bounding rectangle
              const boundingBox = polyline.getBoundingBox();
              if (boundingBox)
                map.getViewModel().setLookAtData({
                  bounds: boundingBox,
                });
            });
          },
          console.error
        );
      }
    } else {
      // Let's zoom to our objects by default
      map.getViewModel().setLookAtData({
        bounds: container.getBoundingBox(),
      });
    }

    map.addObject(container);
  };

  const createInfoBubble = (
    map: H.Map,
    marker: H.map.Marker,
    location: Location
  ) => {
    // Create the info bubble element and add it to the DOM
    const bubble = document.createElement("div");
    bubble.classList.add("info-bubble");
    bubble.style.display = "none";
    bubble.innerText = location.name;
    map.getElement().appendChild(bubble);

    // Add event listeners to show and hide the info bubble on mouseover/mouseout
    marker.addEventListener("pointerenter", () => {
      const markerPos: any = map.geoToScreen({
        lat: location.latitude,
        lng: location.longitude,
      });
      bubble.style.display = "block";
      const offset = new H.math.Point(
        -(bubble.offsetWidth / 2),
        -(bubble.offsetHeight + 40)
      );
      const bubblePos = markerPos.add(offset);
      bubble.style.left = `${bubblePos.x}px`;
      bubble.style.top = `${bubblePos.y}px`;
    });

    marker.addEventListener("pointerleave", () => {
      bubble.style.display = "none";
    });
  };

  // Function to handle resizing of the map
  const handleResize = (): void => {
    if (map) {
      map.getViewPort().resize();
    }
  };

  const setLocation = (locationCode: string) => {
    const changeEnd =
      locationsState.locationsB
        .concat(locationsState.locationsA)
        .find((x) => x.locationCode == bookingState.outboundTrip.startNode.code)
        ?.connection ?? true;
    const start = changeEnd
      ? bookingState.outboundTrip.startNode.code
      : locationCode;
    const end = changeEnd
      ? locationCode
      : bookingState.outboundTrip.endNode.code;
    if (bookingState.ticketType === BOOKING_TICKET_TYPE.ONEWAY) {
      dispatch(setInboundNode({ start: "", end: "" }));
      dispatch(setOutboundNode({ start: start, end: end }));
    } else if (bookingState.ticketType === BOOKING_TICKET_TYPE.RETURN) {
      dispatch(setInboundNode({ start: end, end: start }));
      dispatch(setOutboundNode({ start: start, end: end }));
    }
  };

return (
    <div id="map" className="absolute top-0 left-0 w-full h-full" ref={ref}>
        <div id="scroll-fixer" className="absolute w-full h-full z-10" style={{ display: "none" }}></div>
    </div>
);
}

export default Map;

const Icon = (selected: boolean): H.map.Icon => {
  const s = ReactDOMServer.renderToString(
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
    >
      <path
        fill={selected ? "rgba(0, 128, 255, 0.7)" : "rgb(50, 50, 50, 0.5)"}
        d="M12 0c-4.198 0-8 3.403-8 7.602 0 4.198 3.469 9.21 8 16.398 4.531-7.188 8-12.2 8-16.398 0-4.199-3.801-7.602-8-7.602zm0 11c-1.657 0-3-1.343-3-3s1.343-3 3-3 3 1.343 3 3-1.343 3-3 3z"
      />
    </svg>
  );

  const size = selected ? 50 : 30;
  return new H.map.Icon(s, {
    size: { w: size, h: size },
    anchor: { x: size / 2, y: size },
  });
};
