import { useState } from "react";
import { Outlet, useNavigate, useParams } from "react-router-dom";
import { useAppDispatch } from "../app/hooks";
import { reset, setSiteCode } from "./Booking/bookingSlice";
import {
  getBoot,
} from "./../services/Booking/api";
import { useErrorBoundary } from "react-error-boundary";
import { BOOKING_TYPE } from "../types/booking";

const SiteRouter = () => {
  const params = useParams();
  const { sitecode } = params;
  const { showBoundary } = useErrorBoundary();
  const navigate = useNavigate();
  const [loading, setLoading] = useState<Boolean>(true);
  let storedCode = sessionStorage.getItem('siteCode');
  const dispatch = useAppDispatch();

  //first time

  setTimeout(async () => {
    
    const old_siteCode = sessionStorage.getItem('siteCode');
    if(old_siteCode && old_siteCode != sitecode)
    {
      dispatch(reset(BOOKING_TYPE.FLIGHT));
    }

    sessionStorage.setItem('siteCode', sitecode ?? "");
    dispatch(setSiteCode(sitecode ?? ""));

    if (storedCode) {
      //console.log("Stored siteCode is " + storedCode + " and supplied code is " + sitecode );
      // if not same, reboot
      if (storedCode !== sitecode) {
        sessionStorage.clear();
        dispatch(setSiteCode(""));
        window.location.reload();
        return;
      }
      setLoading(false);

    } else {
      const valid = await getData();
      console.log({ valid: valid })
      if (!valid) {
        sessionStorage.clear();
        dispatch(setSiteCode(""));
        navigate("/");
        return;
      }
    }

  }, storedCode ? 0 : 0);


  const getData = async (): Promise<boolean> => {
    try {
      const response = await getBoot(showBoundary);
      return response.valid;
    } catch (error) {
      console.log(error);
      return false; // Return false in case of an error
    }
  };


  return (
    <>
      {loading &&
        <div className="absolute inset-0 flex justify-center items-center">
          <img src={"ft_spinner.svg"} alt="Loading" />
        </div>
      }

      {!loading &&
        <Outlet />
      }

    </>

  )
}

export default SiteRouter 