import { t } from "i18next";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import Skeleton from "react-loading-skeleton";
import Overview from "./BookingFindOverview";
import { BookingApi } from "../../services/Booking/type";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import bookingSlice, { selectBooking, setBookingType } from "./bookingSlice";
import { BOOKING_TYPE } from "../../types/booking";
import { useNavigate } from 'react-router-dom';
import { toast } from "react-toastify";
import { SubmitHandler, useForm } from "react-hook-form";

interface BookingFindProp {
  id: string | null;
  email: string | null;
  code: string | null;
}

export default function BookingFind({ id, email, code }: BookingFindProp) {
  const [number, setNumber] = useState<string | null>(id);
  const [emailAddress, setEmailAddress] = useState<string | null>(email);
  const [searchBooking, setSearchBooking] = useState<boolean>(false);
  const navigate = useNavigate();

  const { t, i18n } = useTranslation();
  const bookingState = useAppSelector(selectBooking);

  const dispatch = useAppDispatch();
  const { register, handleSubmit, formState: { errors } } = useForm<FormValues>();

  useEffect(() => {
    if ((id && email && (code === null || code === "200"))) {
      setNumber(id);
      setEmailAddress(email);
      setSearchBooking(true);

    } else {
      const numberFromParams = id;
      const emailFromParams = email;

      if (numberFromParams) {
        setNumber(numberFromParams);
      }

      if (emailFromParams) {
        setEmailAddress(emailFromParams);
      }

      if((code) && code !=="200"){
        switch(code){
          case "400":
            toast.warn(t('toast.warn_bookingseach_bad'));
            break;
          case "403":
            toast.warn(t('toast.warn_bookingseach_forbid'));
            break;
          case "404":
            toast.warn(t('toast.warn_bookingseach_notfound'));
            break;
          default:
            toast.warn(t('toast.warn_bookingseach_notfound'));
        }
      }
    }
    
    dispatch(setBookingType(BOOKING_TYPE.MYBOOKING));
  }, []);

  useEffect(() => {
    const fetchMyBooking = async () => {
      navigate(`/${bookingState.siteCode}/reference?id=${number}&email=${emailAddress}`);
    };

    if (!searchBooking) return;
    fetchMyBooking();
  }, [searchBooking]);


  const onSubmit: SubmitHandler<FormValues> = (data) => {
    if (!(data.number && data.email)) return;
    setSearchBooking(true);
  };

  type FormValues = {
    number: string;
    email: string;
  };

  return (
    <>
        <>
        <form onSubmit={handleSubmit(onSubmit)} className="grid grid-cols-1 gap-6 mt-4 md:grid-cols-2">
            <div>
              <label
                className="text-gray-700 dark:text-gray-200"
                htmlFor="number"
              >
                {t("bookingnumber", { ns: "common" })}
              </label>
              <input
                id="number"
                type="text"
                {...register('number', { pattern: /^[0-9]+$/ })}
                className="block w-full px-4 py-2 mt-2 text-gray-700 bg-white border border-gray-200 rounded-md focus:border-blue-400 focus:ring-blue-300 focus:ring-opacity-40 focus:outline-none focus:ring"
                value={number ?? ""}
                onChange={(e) => setNumber(e.target.value)}
              />
               {errors.number && (
                <p className="text-red-500 text-xs mt-1 italic">{t("valid.bookingref")}</p>
        )}
            </div>

            <div>
              <label
                className="text-gray-700 dark:text-gray-200"
                htmlFor="email"
              >
                {t("email", { ns: "common" })}
              </label>
              <input
                id="email"
                type="text"
                {...register('email', { pattern: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i })}
                className="block w-full px-4 py-2 mt-2 text-gray-700 bg-white border border-gray-200 rounded-md focus:border-blue-400 focus:ring-blue-300 focus:ring-opacity-40 focus:outline-none focus:ring"
                value={emailAddress ?? ""}
                onChange={(e) => setEmailAddress(e.target.value)}
              />
               {errors.email && (
                  <p className="text-red-500 text-xs mt-1 italic">{t("valid.email_err",  {ns: "common"})}</p>

                )}
            </div>

            <div className="w-full flex flex-col-reverse items-center gap-y-6 my-6 md:col-span-2 lg:flex-row lg:justify-between">
              <div className="hidden md:block"></div>
              <button type="submit" className={`w-full py-4 px-12 text-white font-bold bg-ft-blue-dark border-1 uppercase lg:w-auto
                    ${ !number || !emailAddress ? "opacity-50 cursor-not-allowed" : "" }`} >
                {t("continue", { ns: "common" })}
              </button>
            </div>
        </form>
        </>
    </>
  );
}
