import i18n from 'i18next'
import { FaqSection } from "./Type";
import constants from '../../constants/constants';
import fetchWithCustomHeader from '../FetchUtil';
import { Convert } from '../../Utils/LangConverter';

export async function getFaq(filter: string[]): Promise<FaqSection[]> {
    const params = new URLSearchParams(filter.map(s=>['filter',s]));
    
    const response = await fetchWithCustomHeader(`${constants.Api.API_URL}/faq`);
    return response.json();
}

export type { FaqSection };
