import { Popover } from '@headlessui/react'
import { CheckIcon, ChevronDownIcon } from '@heroicons/react/20/solid'
import { useState, useEffect } from 'react'
import i18n, { changeLanguage } from 'i18next'

export default function Example() {

    const [currentLanguage, setCurrentLanguage] = useState(() => {
        return convertCodeToLanguage(i18n.language);
    });

    useEffect(() => {
        const handleLanguageChange = () => {
            setCurrentLanguage(convertCodeToLanguage(i18n.language));
        };

        i18n.on('languageChanged', handleLanguageChange);

        return () => {
            i18n.off('languageChanged', handleLanguageChange);
        };
    }, []);    

    return (
        <div className="flex justify-end px-2">
            <Popover as="div" className="relative">
                {({ open, close }) => (
                    <>
                        <Popover.Button>
                            <div className="flex bg-white rounded py-2 pr-3 pl-5">
                                {<currentLanguage.icon />} <ChevronDownIcon className={`${open ? '' : 'text-opacity-70'} ml-2 h-5 w-5 text-blue-300 transition duration-150 ease-in-out group-hover:text-opacity-80`} aria-hidden="true" />
                            </div>
                        </Popover.Button>
                        <Popover.Panel className="bg-white text-gray-700 shadow-md rounded text-sm absolute mt-12 top-0 right-0 min-w-full w-48 z-30">
                            <>
                                <span className="absolute top-0 right-0 w-3 h-3 bg-white transform rotate-45 -mt-1 mr-3"></span>
                                <div className="bg-white overflow-auto rounded w-full relative z-10">
                                    <ul className="list-reset">
                                        {languages.map((item, index) =>
                                        (
                                            <li className="p-0" key={index}>
                                                <a onClick={() => { changeLanguage(item.code); close() }} className="px-4 py-2 flex hover:bg-gray-100 no-underline hover:no-underline transition-colors duration-100 hover:cursor-pointer">
                                                    {<item.icon />}
                                                    <span className="inline-block ml-2">{item.name}</span>
                                                    {currentLanguage.code == item.code && <CheckIcon className={`ml-auto h-5 w-5 text-blue-300`} aria-hidden="true" />}
                                                </a>
                                            </li>
                                        ))}
                                    </ul>
                                </div>
                            </>
                        </Popover.Panel>
                    </>
                )
                }
            </Popover >
        </div>
    )
}

const flag = {
    sv: () => {
        return (
            <svg xmlns="http://www.w3.org/2000/svg" id="flag-icons-se" viewBox="0 0 640 480" height="20">
                <path fill="#005293" d="M0 0h640v480H0z" />
                <path fill="#fecb00" d="M176 0v192H0v96h176v192h96V288h368v-96H272V0h-96z" />
            </svg>
        );
    },
    en: () => {
        return (
            <svg xmlns="http://www.w3.org/2000/svg" id="flag-icons-gb" viewBox="0 0 640 480" height="20">
                <path fill="#012169" d="M0 0h640v480H0z" />
                <path fill="#FFF" d="m75 0 244 181L562 0h78v62L400 241l240 178v61h-80L320 301 81 480H0v-60l239-178L0 64V0h75z" />
                <path fill="#C8102E" d="m424 281 216 159v40L369 281h55zm-184 20 6 35L54 480H0l240-179zM640 0v3L391 191l2-44L590 0h50zM0 0l239 176h-60L0 42V0z" />
                <path fill="#FFF" d="M241 0v480h160V0H241zM0 160v160h640V160H0z" />
                <path fill="#C8102E" d="M0 193v96h640v-96H0zM273 0v480h96V0h-96z" />
            </svg>
        );
    },
    unknown: () => {
        return (
            <svg xmlns="http://www.w3.org/2000/svg" id="flag-icons-xx" viewBox="0 0 640 480" height="20">
                <path fill="#fff" fillRule="evenodd" stroke="#adb5bd" strokeWidth="1.1" d="M.5.5h638.9v478.9H.5z" />
                <path fill="none" stroke="#adb5bd" strokeWidth="1.1" d="m.5.5 639 479M639.5.5l-639 479" />
            </svg>
        );
    },
}

interface Language {
    code: string;
    name: string;
    icon: () => JSX.Element;
}

const languages: ReadonlyArray<Language> = [
    { code: 'en', name: 'English', icon: flag.en },
    { code: 'sv', name: 'Svenska', icon: flag.sv },
];

const convertCodeToLanguage = (langCode: String) => {
    return languages.find(l => l.code == (langCode?.substring(0, 2).toLowerCase())) ?? { code: 'en', name: 'English', icon: flag.en };
}


