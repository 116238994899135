import { configureStore, ThunkAction, Action } from '@reduxjs/toolkit';
import bookingReducer from '../components/Booking/bookingSlice';
import locationsReducer from './locationSlice';



const getInitialState = () => {
  const serializedState = sessionStorage.getItem('bookingState');
  if (serializedState === null) {
    return undefined; // Return undefined if no state is stored
  }
  return JSON.parse(serializedState); // Parse the stored state as JSON
};


export const store = configureStore({
  reducer: {
    booking: bookingReducer,
    locations : locationsReducer
  },
  preloadedState: getInitialState(),
});

store.subscribe(() => {
  const state = store.getState();
  const serializedState = JSON.stringify(state);
  sessionStorage.setItem('bookingState', serializedState);
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
