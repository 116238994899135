import { useEffect, useState, useCallback } from 'react'
import { Disclosure, Transition } from '@headlessui/react'
import { getFaq, FaqSection } from "../../services/Faq/Api";
import Skeleton from 'react-loading-skeleton';
import i18n from 'i18next'

interface FaqProperties {
    filter?: string[];
}

function Faq({ filter }: FaqProperties) {

    const [faqSections, setFaqSection] = useState<FaqSection[]>([]);
    const [loading, setLoading] = useState(true);

    const [searchQuery, setSearchQuery] = useState('');

    const handleSearch = (event: { target: { value: string; }; }) => {
        setSearchQuery(event.target.value.toLowerCase());
    };

    function resetToLoading() {
        setFaqSection([]);
        setLoading(true);
    }

    const handleLanguageChange = async () => {
        resetToLoading();

        try {
            //await new Promise(resolve => setTimeout(resolve, 3000));
            const sections = await getFaq(filter ?? []);
            if (sections.length > 0)
                sections[0].active = true;

            setFaqSection(sections);
        } catch (error) {
            setFaqSection([]);
        }

        setLoading(false);
    };

    useEffect(() => {
        handleLanguageChange();

        i18n.on('languageChanged', handleLanguageChange);

        return () => {
            i18n.off('languageChanged', handleLanguageChange);
        };
    }, [filter]);

    return (
        <section id="faq" className="bg-white">

            <div className="container mx-auto px-6 py-12 max-w-5xl">

                <h1 className="text-2xl font-semibold text-center text-gray-800 lg:text-3xl dark:text-white">{i18n.t("faq", { ns: "common" })}</h1>

                <div className="relative flex items-center mt-2">
                    <span className="absolute text-gray-400 left-3">
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-5 h-5"><path strokeLinecap="round" strokeLinejoin="round" d="M21 21l-5.197-5.197m0 0A7.5 7.5 0 105.196 5.196a7.5 7.5 0 0010.607 10.607z"></path></svg>
                    </span>

                    <input type="search" placeholder="Search..." className="block w-full py-2.5 text-gray-700 placeholder-gray-400/70 bg-white border border-gray-200 rounded-lg pl-11 pr-5 focus:ring-blue-300 focus:outline-none focus:ring focus:ring-opacity-40" value={searchQuery} onChange={handleSearch} />
                </div>

                {
                    loading ?
                        (
                            <>
                                <div className="flex mt-4 items-start gap-x-4 flex-wrap">
                                    <Skeleton height={30} width={100} className="my-1 rounded-full group" />
                                    <Skeleton height={30} width={150} className="my-1 rounded-full group" />
                                    <Skeleton height={30} width={200} className="my-1 rounded-full group" />
                                </div>
                            </>
                        )
                        :
                        (
                            faqSections?.length > 1 &&
                            <div className="flex mt-4 items-start gap-x-4 flex-wrap">
                                {faqSections?.map((section, i) => (
                                    <a key={i} rel="noopener noreferrer" className={`inline-flex items-center px-3 py-1 my-1 space-x-2 text-sm border rounded-full group border-ft-blue-dark ${!section.active ? null : 'bg-ft-blue-dark'} hover:cursor-pointer`} onClick={() => {
                                        const updatedSections = [...faqSections];
                                        updatedSections[i] = { ...section, active: !section.active };
                                        setFaqSection(updatedSections);
                                    }}>
                                        <span className={`group-hover:underline ${!section.active ? 'text-ft-blue-dark' : 'text-white'}`}>{section.title}</span>
                                    </a>
                                ))}
                            </div>
                        )
                }

                <div className="flex-1 mt-8 lg:mx-12 lg:mt-0">
                    {loading ?
                        (
                            <>
                                <Skeleton className="mb-8" height={22} width={125} />
                                {[...Array(4)].map((_, i) => (
                                    <div key={i}>

                                        <div className='flex'>
                                            <Skeleton height={22} width={25} />
                                            <Skeleton className='mx-4' height={22} width={300} />
                                        </div>
                                        {
                                            i !== 3 &&
                                            <hr className="my-8 border-gray-200" />
                                        }
                                    </div>
                                ))}
                            </>
                        )
                        :
                        (
                            <>

                                {
                                    (faqSections.some(section => section.active) ? faqSections.filter(section => section.active) : (searchQuery === "" ? [] : faqSections))
                                        .map((s, i) => (
                                            s.questions.filter((question) => {
                                                return s.title.toLowerCase().includes(searchQuery) ||
                                                    question.question.toLowerCase().includes(searchQuery) ||
                                                    question.answer.toLowerCase().includes(searchQuery);
                                            }).length > 0 && (
                                                <div key={i} className="mb-14">
                                                    <h1 className="my-8 text-xl font-semibold text-gray-800">{s.title}</h1>
                                                    {s.questions.filter((question) => {
                                                        return s.title.toLowerCase().includes(searchQuery) ||
                                                            question.question.toLowerCase().includes(searchQuery) ||
                                                            question.answer.toLowerCase().includes(searchQuery);
                                                    }).map((question, j) => (
                                                        <div key={j}>
                                                            <Disclosure as="div">
                                                                {({ open }) => (
                                                                    <>
                                                                        <Disclosure.Button className="flex items-center focus:outline-none">
                                                                            {
                                                                                open ?
                                                                                    <svg className="flex-shrink-0 w-6 h-6 text-ft-blue-dark" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M20 12H4"></path></svg>
                                                                                    :
                                                                                    <svg xmlns="http://www.w3.org/2000/svg" className="flex-shrink-0 w-6 h-6 text-ft-blue-dark" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                                                                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M12 4v16m8-8H4" />
                                                                                    </svg>
                                                                            }
                                                                            <h1 className="mx-4 text-xl text-gray-700 text-start">{question.question}</h1>
                                                                        </Disclosure.Button>
                                                                        <Transition
                                                                            show={open}
                                                                            enter="transition-all duration-100 ease-out"
                                                                            enterFrom="opacity-0 transform -translate-y-full"
                                                                            enterTo="opacity-100 transform translate-y-0"
                                                                            leave="transition-all duration-100 ease-in"
                                                                            leaveFrom="opacity-100 transform translate-y-0"
                                                                            leaveTo="opacity-0 transform -translate-y-full"
                                                                        >
                                                                            <Disclosure.Panel className="flex mt-8 md:mx-10">
                                                                                <span className="border border-ft-blue-dark"></span>
                                                                                <p className="max-w-3xl px-4 text-gray-500" dangerouslySetInnerHTML={{ __html: question.answer }}></p>
                                                                            </Disclosure.Panel>
                                                                        </Transition>
                                                                    </>
                                                                )}
                                                            </Disclosure>
                                                            {
                                                                j !== s.questions.length - 1 &&
                                                                <hr className="my-8 border-gray-200" />
                                                            }
                                                        </div>
                                                    ))}
                                                </div>
                                            )
                                        ))
                                }
                            </>
                        )
                    }
                </div>
            </div>

        </section >
    )
}

export default Faq;