import { useAppDispatch } from '../app/hooks';
import LanguagePicker from './LanguagePicker';
import { useNavigate } from 'react-router-dom';

import {
  BOOKING_STATUS,
  setStatus
} from "../components/Booking/bookingSlice";
import { useEffect, useState } from 'react';
import { getTransferLogo } from '../services/Booking/api';

export default function Navbar() {

  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [logoUrl, setLogoUrl] = useState<string>("");

  const toIndex = () => dispatch(setStatus(BOOKING_STATUS.INIT))//navigate('/');

  useEffect(() => {

    const getLogo = async () => {
      try {
        const data = await getTransferLogo();
        setLogoUrl(`url('${data?.url != null ? data.url : '/flygtaxi.png'}')`)
      } catch (error) {
        console.error("Error fetching logo url:", error);
      }
    };

    getLogo();
  }
    , [])

  return (
    <nav className="relative w-full p-5 flex bg-white shadow-[0_0.1rem_0.8rem_0_rgba(51,51,51,0.1)] z-10">
      <div className="flex-1 hidden sm:block" />
      <div className="bg-center bg-no-repeat bg-contain w-48 md:w-72 hover:cursor-pointer" style={{ backgroundImage: logoUrl }} onClick={toIndex} />
      <div className="flex-1 flex items-center justify-end"><LanguagePicker /></div>
    </nav>
  );
}
