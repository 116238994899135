import { useEffect, useState } from "react";
import BookingFlight from "./BookingFlight";
import BookingFind from "./BookingFind";
import { useTranslation } from "react-i18next";
import { useSearchParams } from "react-router-dom";
import { BOOKING_TYPE } from "../../types/booking";
import { useAppSelector } from "../../app/hooks";
import { selectBooking } from "./bookingSlice";
import {
  getConnectionTypes
} from "../../services/Booking/api";
export default function StartBooking() {
  const [searchParams, setSearchParams] = useSearchParams();
  const [hasFlights, setHasFlights] = useState<boolean>(false);
  const [hasTrain, setHasTrain] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(true);



  const bookingState = useAppSelector(selectBooking);

  useEffect(() => {
    if (searchParams.has('email') && searchParams.has('id')) {
      setView(2);
    }
  }, [searchParams]);


  useEffect(() => {
    const fetchData = async () => {
      const connectionTypes = await getConnectionTypes();
      
      connectionTypes.forEach(c => {
        
        console.log({c});
        
        if(c.connectiontype === "flight") setHasFlights(true);
        if(c.connectiontype === "train") setHasTrain(true);
      });

      setLoading(false);
    }

   fetchData();

  }, [1]);

  const { t, i18n } = useTranslation();
  const [view, setView] = useState<BOOKING_TYPE>(bookingState.bookingType);

  return (
    <div className="-mt-16 lg:mt-20">
      
      <h1 className="p-6 text-2xl font-semibold text-gray-800 -translate-x-full opacity-0 animate-text-slide-in lg:text-left lg:text-3xl">{t("booking_title", {ns: "translation"})}</h1>

      { !loading && 
      
      <div className="relative max-w-full h-auto bg-white px-6">

      <div className="flex overflow-x-auto overflow-y-hidden border-b border-gray-200 whitespace-nowrap -mx-6">
      { hasFlights &&
        
        <button onClick={() => setView(BOOKING_TYPE.FLIGHT)} className={`flex-1 inline-flex items-center justify-center h-16 px-2 py-2 -mb-px text-center ${view === BOOKING_TYPE.FLIGHT ? 'text-blue-600 bg-transparent border-b-2 border-blue-500 sm:px-4 -px-1 whitespace-nowrap focus:outline-none' : 'text-gray-700 bg-transparent border-b-2 border-transparent sm:px-4 -px-1 whitespace-nowrap cursor-base focus:outline-none hover:border-gray-400'} hover:cursor-pointer`}>
        
        <svg className="w-4 h-4 mx-1 sm:w-6 sm:h-6" stroke="currentColor" fill="currentColor" strokeWidth="0" viewBox="0 0 16 16" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path d="M6.428 1.151C6.708.591 7.213 0 8 0s1.292.592 1.572 1.151C9.861 1.73 10 2.431 10 3v3.691l5.17 2.585a1.5 1.5 0 0 1 .83 1.342V12a.5.5 0 0 1-.582.493l-5.507-.918-.375 2.253 1.318 1.318A.5.5 0 0 1 10.5 16h-5a.5.5 0 0 1-.354-.854l1.319-1.318-.376-2.253-5.507.918A.5.5 0 0 1 0 12v-1.382a1.5 1.5 0 0 1 .83-1.342L6 6.691V3c0-.568.14-1.271.428-1.849Zm.894.448C7.111 2.02 7 2.569 7 3v4a.5.5 0 0 1-.276.447l-5.448 2.724a.5.5 0 0 0-.276.447v.792l5.418-.903a.5.5 0 0 1 .575.41l.5 3a.5.5 0 0 1-.14.437L6.708 15h2.586l-.647-.646a.5.5 0 0 1-.14-.436l.5-3a.5.5 0 0 1 .576-.411L15 11.41v-.792a.5.5 0 0 0-.276-.447L9.276 7.447A.5.5 0 0 1 9 7V3c0-.432-.11-.979-.322-1.401C8.458 1.159 8.213 1 8 1c-.213 0-.458.158-.678.599Z"></path></svg>

          <span className="mx-1 text-sm sm:text-base">
            {t("airplane")}
          </span>
        </button>
      
      }
      { hasTrain &&
          <button onClick={() => setView(BOOKING_TYPE.TRAIN)} className={`flex-1 inline-flex items-center justify-center h-16 px-2 py-2 -mb-px text-center ${view === BOOKING_TYPE.TRAIN ? 'text-blue-600 bg-transparent border-b-2 border-blue-500 sm:px-4 -px-1 whitespace-nowrap focus:outline-none' : 'text-gray-700 bg-transparent border-b-2 border-transparent sm:px-4 -px-1 whitespace-nowrap cursor-base focus:outline-none hover:border-gray-400'} hover:cursor-pointer`}>
      
          <svg className="w-4 h-4 mx-1 sm:w-6 sm:h-6" stroke="currentColor" fill="currentColor" strokeWidth="0" viewBox="0 0 24 24" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path fill="none" d="M0 0h24v24H0V0z"></path><circle cx="8.5" cy="14.5" r="1.5"></circle><circle cx="15.5" cy="14.5" r="1.5"></circle><path d="M12 2c-4 0-8 .5-8 4v9.5C4 17.43 5.57 19 7.5 19L6 20.5v.5h2l2-2h4l2 2h2v-.5L16.5 19c1.93 0 3.5-1.57 3.5-3.5V6c0-3.5-4-4-8-4zm0 2c3.51 0 4.96.48 5.57 1H6.43c.61-.52 2.06-1 5.57-1zM6 7h5v3H6V7zm12 8.5c0 .83-.67 1.5-1.5 1.5h-9c-.83 0-1.5-.67-1.5-1.5V12h12v3.5zm0-5.5h-5V7h5v3z"></path></svg>
  
              <span className="mx-1 text-sm sm:text-base">
                {t("train")}
              </span>
            </button>
      }

      
      <button onClick={() => setView(BOOKING_TYPE.MYBOOKING)} className={`flex-1 inline-flex items-center justify-center h-16 px-2 py-2 -mb-px text-center ${view === BOOKING_TYPE.MYBOOKING ? 'text-blue-600 bg-transparent border-b-2 border-blue-500 sm:px-4 -px-1 whitespace-nowrap focus:outline-none' : 'text-gray-700 bg-transparent border-b-2 border-transparent sm:px-4 -px-1 whitespace-nowrap cursor-base focus:outline-none hover:border-gray-400'} hover:cursor-pointer`}>
      
      <svg className="w-4 h-4 mx-1 sm:w-6 sm:h-6" stroke="currentColor" fill="none" strokeWidth="2" viewBox="0 0 24 24" strokeLinecap="round" strokeLinejoin="round" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path stroke="none" d="M0 0h24v24H0z" fill="none"></path><path d="M15 5l0 2"></path><path d="M15 11l0 2"></path><path d="M15 17l0 2"></path><path d="M5 5h14a2 2 0 0 1 2 2v3a2 2 0 0 0 0 4v3a2 2 0 0 1 -2 2h-14a2 2 0 0 1 -2 -2v-3a2 2 0 0 0 0 -4v-3a2 2 0 0 1 2 -2"></path></svg>

          <span className="mx-1 text-sm sm:text-base">
            {t("mybooking")}
          </span>
        </button>

      </div>

      {(() => {
        switch (view) {
          case 0:
            return <BookingFlight type={BOOKING_TYPE.FLIGHT} key={'F'} />;
          case 1:
            return <BookingFlight type={BOOKING_TYPE.TRAIN} key={'T'} />;
          case 2:
            return <BookingFind id={searchParams.get('id')} email={searchParams.get('email')} code={searchParams.get('code')}/>;
        }
      })()}
    </div>
      
      
      }




    </div>
  );
}
